import moment from "moment";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import _ from "lodash";
import { URLS } from "../../services/urls";
import { useSelector } from "react-redux";
import { formatNumber, sumArray } from "../../components/utils/functions";
import { DatePicker } from "react-responsive-calendar-picker";
import { useReactToPrint } from "react-to-print";
import LoaderPage from "../../components/LoaderPage";
import PageTitle from "../../components/PageTitle";
import DailyReportCard from "./components/DailyReportCard";

const formatDate = (date) => {
  if (date) {
    // return String(date.toLocaleDateString()).split('/').reverse().join('-');
    return moment(date).format("YYYY-MM-DD");
  } else {
    return "9999-01-01";
  }
};

const DailyReport = () => {
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf("month").format("YYYY-MM-DD")),
    checkout: new Date(moment().endOf("month").format("YYYY-MM-DD")),
  });

  const { active_agency } = useSelector((state) => state.agencies);

  const reportRef = useRef();

  const {
    data: report,
    isLoading: loadingCa,
    mutate: mutateCa,
  } = useSWR(
    URLS.STATS.REPPORT.daily(
      formatDate(dates.checkin),
      formatDate(dates.checkout),
      active_agency?.id
    )
  );

  const handleChangeDates = (dates) => {
    console.log(dates);
    setDates(dates);
  };

  const getTodayObj = (objs) => {
    // console.log(objs)
    if (objs) {
      const item = objs?.filter((obj) => {
        if (moment(obj.date).isSame(moment(), "day")) {
          console.log("Valid date");
          return obj;
        }
      });
      return item[0];
    }
  };

  const handlePrint = useReactToPrint({
    content: () => reportRef?.current,
    documentTitle: `Rapport du ${
      dates.checkin && dates.checkin.toLocaleDateString()
    } {' '}
    ${dates.checkout && "au"}{' '}
    ${dates.checkout && dates.checkout.toLocaleDateString()}`,
    // onBeforeGetContent: () => setHideButton(true),
    // onAfterPrint: () => setHideButton(true)
  });
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <PageTitle
            title={"Rapport d'activité"}
            breadcrumbs={
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Rapport d'activité
                </li>
              </ol>
            }
          />
        </div>
      </div>

      <div className="card ">
        <div className="card-body rapport-activity">
          <div className="py-3">
            <h3 className="text-uppercase fw-bold">Rapport d'activité</h3>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div>
                  <div
                    className="py-3 position-relative pos-left"
                    style={{ zIndex: "1" }}
                  >
                    <DatePicker
                      dates={dates}
                      setDates={handleChangeDates}
                      open={open}
                      setOpen={setOpen}
                      normal={true}
                    >
                      <button
                        className="btn btn-primary rounded-0"
                        onClick={() => setOpen(!open)}
                      >
                        Rapport du{" "}
                        {dates.checkin && dates.checkin.toLocaleDateString()}{" "}
                        {dates.checkout && "au"}{" "}
                        {dates.checkout && dates.checkout.toLocaleDateString()}
                      </button>
                    </DatePicker>
                  </div>
                </div>
                <div className="ms-auto my-auto">
                  <div className="d-flex">
                    <Link
                      to={"/repports/daily/calendar"}
                      className="btn me-2 btn-primary rounded-0 text-uppercase"
                    >
                      Voir le calendrier
                    </Link>
                    <button
                      className="btn btn-primary rounded-0 text-uppercase"
                      onClick={handlePrint}
                    >
                      Imprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {loadingCa && (
              <div className="py-3">
                <LoaderPage label="Generation du rapport en cours..." />
              </div>
            )}
            {!loadingCa && (
              <div className="col-md-12" ref={reportRef}>
                <div className="border border-1 border-secondary p-3">
                  <div className="my-3 text-center">{/* <PdfHeader /> */}</div>

                  {!loadingCa && report && (
                    <div className="col-md-12" ref={reportRef}>
                      <div className="border p-3 rapport-activity">
                        <div className="repport-page-content">
                          <div className="row op">
                            <div className="col-md-1"></div>
                            <DailyReportCard report={report} />
                            <div className="col-md-5">
                              <div className="bg-light p-3 mb-3">
                                <p className="text-uppercase mb-0 text-center">
                                  Mois en cours
                                </p>
                              </div>
                              <div className="border rounded-11 p-2">
                                <div className="border rounded-11 border-primary">
                                  <table className="table text-uppercase text-left">
                                    <thead className="bg-light">
                                      <tr>
                                        <th>Libelles</th>
                                        <th>Valeur</th>
                                        <th style={{ width: "30px" }}>
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="badge-soft-success">
                                        <td>
                                          Chiffre d'affaires sur les Prestations
                                        </td>
                                        <td>
                                          <span>
                                            {formatNumber(
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    i?.report?.map(
                                                      (i) => i.amount_services
                                                    )
                                                  )
                                                )
                                              )
                                            )}
                                          </span>
                                        </td>
                                        <td className="text-success text-center">
                                          +
                                        </td>
                                      </tr>
                                      {/* Details des prestations */}
                                      {report?.stats[0]?.report?.map(
                                        (i, index) =>
                                          i?.category?.type === "SERVICE" &&
                                          String(
                                            i?.category?.name
                                          )?.toUpperCase() === "COIFFURES" ? (
                                            <tr
                                              className=" small"
                                              key={"prsta-" + index}
                                            >
                                              <td>
                                                <span className="d-flex">
                                                  <span>
                                                    - {i?.category?.name}
                                                  </span>
                                                  <span className="ms-auto border border-primary px-2">
                                                    {formatNumber(
                                                      sumArray(
                                                        report?.stats.map((j) =>
                                                          sumArray(
                                                            j?.report?.map(
                                                              (k) =>
                                                                k.category
                                                                  .id ===
                                                                  i?.category
                                                                    ?.id &&
                                                                i?.category
                                                                  ?.type ===
                                                                  "SERVICE"
                                                                  ? parseFloat(
                                                                      k.amount_services
                                                                    )
                                                                  : 0
                                                            )
                                                          )
                                                        )
                                                      )
                                                    )}
                                                  </span>
                                                </span>
                                              </td>
                                              <td></td>
                                              <td className="text-success text-center"></td>
                                            </tr>
                                          ) : null
                                      )}
                                      {report?.stats[0]?.report?.map(
                                        (i, index) =>
                                          i?.category?.type === "SERVICE" &&
                                          String(
                                            i?.category?.name
                                          )?.toUpperCase() != "COIFFURES" ? (
                                            <tr
                                              className=" small"
                                              key={"prsta-" + index}
                                            >
                                              <td>
                                                <span className="d-flex">
                                                  <span>
                                                    - {i?.category?.name}
                                                  </span>
                                                  <span className="ms-auto border border-primary px-2">
                                                    {formatNumber(
                                                      sumArray(
                                                        report?.stats.map((j) =>
                                                          sumArray(
                                                            j?.report?.map(
                                                              (k) =>
                                                                k.category
                                                                  .id ===
                                                                  i?.category
                                                                    ?.id &&
                                                                i?.category
                                                                  ?.type ===
                                                                  "SERVICE"
                                                                  ? parseFloat(
                                                                      k.amount_services
                                                                    )
                                                                  : 0
                                                            )
                                                          )
                                                        )
                                                      )
                                                    )}
                                                  </span>
                                                </span>
                                              </td>
                                              <td></td>
                                              <td className="text-success text-center"></td>
                                            </tr>
                                          ) : null
                                      )}
                                      <tr className="badge-soft-success">
                                        <td>
                                          Chiffre d'affaires sur les Produits
                                        </td>
                                        <td>
                                          <span>
                                            {formatNumber(
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    i?.report?.map(
                                                      (i) => i.amount_products
                                                    )
                                                  )
                                                )
                                              )
                                            )}
                                          </span>
                                        </td>
                                        <td className="text-success text-center">
                                          +
                                        </td>
                                      </tr>
                                      <tr className="bg-primary-2">
                                        <td>Chiffre d'affaires - Total Nº1</td>
                                        <td>
                                          {formatNumber(
                                            sumArray(
                                              report?.stats.map((i) =>
                                                sumArray(
                                                  i?.report?.map(
                                                    (i) => i.amount_services
                                                  )
                                                )
                                              )
                                            ) +
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    i?.report?.map(
                                                      (i) => i.amount_products
                                                    )
                                                  )
                                                )
                                              )
                                          )}
                                        </td>
                                        <td className="text-success text-center">
                                          +
                                        </td>
                                      </tr>
                                      <tr className="badge-soft-success">
                                        <td>Total des soldes en compte</td>
                                        <td>
                                          {formatNumber(
                                            sumArray(
                                              report?.stats.map(
                                                (i) => i?.soldes_clients
                                              )
                                            )
                                          )}
                                        </td>
                                        <td className="text-success text-center">
                                          +
                                        </td>
                                      </tr>
                                      <tr className="bg-primary-2">
                                        <td>Chiffre d'affaires - Total Nº2</td>
                                        <td>
                                          {formatNumber(
                                            sumArray(
                                              report?.stats.map((i) =>
                                                sumArray(
                                                  i?.report?.map(
                                                    (i) => i.amount_services
                                                  )
                                                )
                                              )
                                            ) +
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    i?.report?.map(
                                                      (i) => i.amount_products
                                                    )
                                                  )
                                                )
                                              ) +
                                              getTodayObj(report?.stats)
                                                ?.soldes_clients
                                          )}
                                        </td>
                                        <td className="text-danger text-center"></td>
                                      </tr>
                                      <tr className="badge-soft-danger ">
                                        <td>Charges Globales</td>
                                        <td>
                                          {formatNumber(
                                            sumArray(
                                              report?.stats.map((i) =>
                                                sumArray(
                                                  _.filter(i?.caisses ?? [], {
                                                    is_admin: false,
                                                    is_main: true,
                                                    mobile: null,
                                                  })[0]?.transactions.map((j) =>
                                                    j?.type === "WITHDRAWAL"
                                                      ? parseFloat(j?.amount)
                                                      : 0
                                                  )
                                                )
                                              )
                                            ) +
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    _.filter(i?.caisses ?? [], {
                                                      mobile: null,
                                                      is_main: true,
                                                      is_admin: true,
                                                    })[0]?.transactions.map(
                                                      (j) =>
                                                        j?.type === "WITHDRAWAL"
                                                          ? parseFloat(
                                                              j?.amount
                                                            )
                                                          : 0
                                                    )
                                                  )
                                                )
                                              )
                                          )}
                                        </td>
                                        <td className="text-danger text-center">
                                          -
                                        </td>
                                      </tr>
                                      <tr className="small">
                                        <td>
                                          <span className="d-flex">
                                            <span className="">
                                              - Caisse principale
                                            </span>
                                            <span className="ms-auto border border-primary px-2">
                                              {formatNumber(
                                                sumArray(
                                                  report?.stats.map((i) =>
                                                    sumArray(
                                                      _.filter(
                                                        i?.caisses ?? [],
                                                        {
                                                          is_admin: false,
                                                          is_main: true,
                                                          mobile: null,
                                                        }
                                                      )[0]?.transactions.map(
                                                        (j) =>
                                                          j?.type ===
                                                          "WITHDRAWAL"
                                                            ? parseFloat(
                                                                j?.amount
                                                              )
                                                            : 0
                                                      )
                                                    )
                                                  )
                                                )
                                              )}
                                            </span>
                                          </span>
                                        </td>
                                        <td></td>
                                        <td className="text-danger text-center"></td>
                                      </tr>
                                      <tr className="small">
                                        <td>
                                          <span className="d-flex">
                                            <span className="">- Coffre</span>
                                            <span className="ms-auto border border-primary px-2">
                                              {formatNumber(
                                                sumArray(
                                                  report?.stats.map((i) =>
                                                    sumArray(
                                                      _.filter(
                                                        i?.caisses ?? [],
                                                        {
                                                          is_admin: true,
                                                          is_main: true,
                                                          mobile: null,
                                                        }
                                                      )[0]?.transactions.map(
                                                        (j) =>
                                                          j?.type ===
                                                          "WITHDRAWAL"
                                                            ? parseFloat(
                                                                j?.amount
                                                              )
                                                            : 0
                                                      )
                                                    )
                                                  )
                                                )
                                              )}
                                            </span>
                                          </span>
                                        </td>
                                        <td></td>
                                        <td className="text-danger text-center"></td>
                                      </tr>
                                      <tr className="bg-primary-2">
                                        <td>Marge Commerciale Nº1</td>
                                        <td>
                                          {formatNumber(
                                            sumArray(
                                              report?.stats.map((i) =>
                                                sumArray(
                                                  i?.report?.map(
                                                    (i) => i.amount_services
                                                  )
                                                )
                                              )
                                            ) +
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    i?.report?.map(
                                                      (i) => i.amount_products
                                                    )
                                                  )
                                                )
                                              ) +
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    _.filter(
                                                      i?.transactions ?? [],
                                                      {
                                                        reason:
                                                          "APPROVISIONNEMENT_DE_COMPTE",
                                                      }
                                                    )?.map((j) => j.amount)
                                                  )
                                                )
                                              ) -
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    _.filter(i?.caisses ?? [], {
                                                      is_admin: false,
                                                      is_main: true,
                                                    })[0]?.transactions.map(
                                                      (j) =>
                                                        j?.type === "WITHDRAWAL"
                                                          ? parseFloat(
                                                              j?.amount
                                                            )
                                                          : 0
                                                    )
                                                  )
                                                )
                                              ) -
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    _.filter(i?.caisses ?? [], {
                                                      is_admin: true,
                                                      is_main: true,
                                                    })[0]?.transactions.map(
                                                      (j) =>
                                                        j?.type === "WITHDRAWAL"
                                                          ? parseFloat(
                                                              j?.amount
                                                            )
                                                          : 0
                                                    )
                                                  )
                                                )
                                              )
                                          )}
                                        </td>
                                        <td className="text-danger text-center">
                                          -
                                        </td>
                                      </tr>
                                      {/* <tr className="badge-soft-danger ">
                                  <td>Elements exceptionnels</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((j) =>
                                          sumArray(
                                            _.filter(j?.payment_methods ?? [], {
                                              payment_method: 'SOLDE_DISPONIBLE',
                                            })[0]?.data.map((i) => parseFloat(i?.amount))
                                          )
                                        )
                                      ) +
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'CREDIT',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        ) +
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'OFFER',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span className="">- Paiements en compte</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            report?.stats.map((j) =>
                                              sumArray(
                                                _.filter(j?.payment_methods ?? [], {
                                                  payment_method: 'SOLDE_DISPONIBLE',
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              )
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                  </td>
                                  <td className="text-danger text-center small"></td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span className="">- Crédit client</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            report?.stats.map((j) =>
                                              sumArray(
                                                _.filter(j?.payment_methods ?? [], {
                                                  payment_method: 'CREDIT',
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              )
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                  </td>
                                  <td className="text-danger text-center"></td>
                                </tr>
                                <tr className="small">
                                  <td>
                                    <span className="d-flex">
                                      <span className="">- Offres/Gratuités</span>
                                      <span className="ms-auto border border-primary px-2">
                                        {formatNumber(
                                          sumArray(
                                            report?.stats.map((j) =>
                                              sumArray(
                                                _.filter(j?.payment_methods ?? [], {
                                                  payment_method: 'OFFER',
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              )
                                            )
                                          )
                                        )}
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                  </td>
                                  <td className="text-danger text-center"></td>
                                </tr> */}
                                      {/* <tr className="bg-primary-2">
                                  <td>Marge Commerciale Nº2</td>
                                  <td>
                                    {formatNumber(
                                      sumArray(
                                        report?.stats.map((i) =>
                                          sumArray(i?.report?.map((i) => i.amount_services))
                                        )
                                      ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(i?.report?.map((i) => i.amount_products))
                                          )
                                        ) +
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              _.filter(i?.transactions ?? [], {
                                                reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                              })?.map((j) => j.amount)
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              _.filter(i?.caisses ?? [], {
                                                is_admin: false,
                                                is_main: true,
                                              })[0]?.transactions.map((j) =>
                                                j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                              )
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((i) =>
                                            sumArray(
                                              _.filter(i?.caisses ?? [], {
                                                is_admin: true,
                                                is_main: true,
                                              })[0]?.transactions.map((j) =>
                                                j?.type === 'WITHDRAWAL' ? parseFloat(j?.amount) : 0
                                              )
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'SOLDE_DISPONIBLE',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'CREDIT',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        ) -
                                        sumArray(
                                          report?.stats.map((j) =>
                                            sumArray(
                                              _.filter(j?.payment_methods ?? [], {
                                                payment_method: 'OFFER',
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        )
                                    )}
                                  </td>
                                  <td className="text-danger text-center">-</td>
                                </tr> */}
                                      <tr className="badge-soft-danger ">
                                        <td>Charges du personnel</td>
                                        <td>
                                          {formatNumber(
                                            sumArray(
                                              report?.stats.map((j) =>
                                                sumArray(
                                                  _.filter(
                                                    j?.transactions ?? [],
                                                    {
                                                      reason: "SALAIRES",
                                                    }
                                                  )?.map((i) => i.amount)
                                                )
                                              )
                                            )
                                          )}
                                        </td>
                                        <td className="text-danger text-center">
                                          -
                                        </td>
                                      </tr>
                                      <tr className="small">
                                        <td>
                                          <span className="d-flex">
                                            <span className="">
                                              - Salaires nets
                                            </span>
                                            <span className="ms-auto border border-primary px-2">
                                              {formatNumber(
                                                sumArray(
                                                  report?.stats.map((j) =>
                                                    sumArray(
                                                      _.filter(
                                                        j?.transactions ?? [],
                                                        {
                                                          reason: "SALAIRES",
                                                        }
                                                      )?.map((i) => i.amount)
                                                    )
                                                  )
                                                )
                                              )}
                                            </span>
                                          </span>
                                        </td>
                                        <td></td>
                                        <td className="text-danger text-center"></td>
                                      </tr>
                                      <tr className=" bg-primary-2">
                                        <td>Résultat</td>
                                        <td>
                                          {formatNumber(
                                            sumArray(
                                              report?.stats.map((i) =>
                                                sumArray(
                                                  i?.report?.map(
                                                    (i) => i.amount_services
                                                  )
                                                )
                                              )
                                            ) +
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    i?.report?.map(
                                                      (i) => i.amount_products
                                                    )
                                                  )
                                                )
                                              ) +
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    _.filter(
                                                      i?.transactions ?? [],
                                                      {
                                                        reason:
                                                          "APPROVISIONNEMENT_DE_COMPTE",
                                                      }
                                                    )?.map((j) => j.amount)
                                                  )
                                                )
                                              ) -
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    _.filter(i?.caisses ?? [], {
                                                      is_admin: false,
                                                      is_main: true,
                                                      mobile: null,
                                                    })[0]?.transactions.map(
                                                      (j) =>
                                                        j?.type === "WITHDRAWAL"
                                                          ? parseFloat(
                                                              j?.amount
                                                            )
                                                          : 0
                                                    )
                                                  )
                                                )
                                              ) -
                                              sumArray(
                                                report?.stats.map((i) =>
                                                  sumArray(
                                                    _.filter(i?.caisses ?? [], {
                                                      is_admin: true,
                                                      is_main: true,
                                                      mobile: null,
                                                    })[0]?.transactions.map(
                                                      (j) =>
                                                        j?.type === "WITHDRAWAL"
                                                          ? parseFloat(
                                                              j?.amount
                                                            )
                                                          : 0
                                                    )
                                                  )
                                                )
                                              ) -
                                              sumArray(
                                                report?.stats.map((j) =>
                                                  sumArray(
                                                    _.filter(
                                                      j?.payment_methods ?? [],
                                                      {
                                                        payment_method:
                                                          "SOLDE_DISPONIBLE",
                                                      }
                                                    )[0]?.data.map((i) =>
                                                      parseFloat(i?.amount)
                                                    )
                                                  )
                                                )
                                              ) -
                                              sumArray(
                                                report?.stats.map((j) =>
                                                  sumArray(
                                                    _.filter(
                                                      j?.payment_methods ?? [],
                                                      {
                                                        payment_method:
                                                          "CREDIT",
                                                      }
                                                    )[0]?.data.map((i) =>
                                                      parseFloat(i?.amount)
                                                    )
                                                  )
                                                )
                                              ) -
                                              sumArray(
                                                report?.stats.map((j) =>
                                                  sumArray(
                                                    _.filter(
                                                      j?.payment_methods ?? [],
                                                      {
                                                        payment_method: "OFFER",
                                                      }
                                                    )[0]?.data.map((i) =>
                                                      parseFloat(i?.amount)
                                                    )
                                                  )
                                                )
                                              ) -
                                              sumArray(
                                                report?.stats.map((j) =>
                                                  sumArray(
                                                    _.filter(
                                                      j?.transactions ?? [],
                                                      {
                                                        reason: "SALAIRES",
                                                      }
                                                    )?.map((i) => i.amount)
                                                  )
                                                )
                                              )
                                          )}
                                        </td>
                                        <td className="text-success text-center"></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-1"></div>
                          </div>
                        </div>
                        {
                          // <DailySummary dates={dates} employee={report.agenncy} />
                        }
                        {/* <div className="bg-light p-3 mb-3">
                    <p className="text-uppercase mb-0 text-center">Autres</p>
                  </div> */}
                      </div>
                    </div>
                  )}
                  {
                    // <DailySummary dates={dates} employee={report.agenncy} />
                  }
                  {/* <div className="bg-light p-3 mb-3">
                    <p className="text-uppercase mb-0 text-center">Autres</p>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyReport;
