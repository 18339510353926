import moment from 'moment';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../components/utils/functions';
import { API } from '../../services/axios';
import { toast } from 'react-toastify';

// {
//     "ca": 0,
//     "sold_products": 0,
//     "account_deposit": 0,
//     "account_payment": 0,
//     "credit_payment": 0,
//     "free_payment": 0,
//     "payment_status": {
//         "PENDING": 0,
//         "COMPLETED": 0,
//         "CANCELLED": 0
//     },
//     "payment_methods": {
//         "NOT_DEFINED": 0,
//         "OM": 0,
//         "MOMO": 0,
//         "PG": 0,
//         "VISA_CARD": 0,
//         "SOLDE_DISPONIBLE": 0,
//         "CASH": 0
//     }
// }

const CloseCheckout = () => {
  const reportRef = useRef();
  const { active_agency, agencies } = useSelector((state) => state.agencies);
  
  const { data: stats, isLoading } = useSWR(URLS.STATS.CA.CLOSE_CHECKOUT.ca(active_agency?.id, moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")));
  const { data: coffre, isLoading: loadingCoffre } = useSWR(URLS.WALLET.admin(active_agency?.id));



  const { data: ca, isLoading: loadingCa } = useSWR(
    URLS.STATS.CA.AGENCY.today(active_agency?.id, moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
  );

  const {
    data: wallets,
    isLoading: loadingWallets,
    mutate: mutateMP,
  } = useSWR(URLS.WALLET.MOBILE.list(active_agency?.id));

  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
        @page {
            margin: 0;
            // background-image: url('/assets/images/pdf-tmp.png') !important;
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
            // background-image: url('/assets/images/pdf-tmp.png') !important;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        },
        body {
          -webkit-print-color-adjust: exact; /* Safari and Chrome */
          color-adjust: exact; /* Firefox */
        }
        .container {
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
          // background-image: url('/assets/images/pdf-tmp.png') !important;
          background-repeat: no-repeat;
          background-size: 100% 100% !important;
          // padding: 150px 100px;
          padding-bottom: 200px
        }
    }`,
    content: () => reportRef?.current,
    documentTitle: 'Rapport journalier ' + moment().format("DD_MM_YYYY"),
  });

  const handleCloseCheckout = () => {
    API.get(URLS.STATS.CA.CLOSE_CHECKOUT.close(active_agency?.id, moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))).then((resp) => {
      toast.success("La caisse a bien été fermée");
      // Marque la caisse comme ayant été fermée
      window.localStorage.setItem('isClosed', true);
    }).catch((error) => {
      toast.error("Une erreur s'est produite lors de la fermeture de la caisse, veuiller réessayer");
    })
  }


  return ( 
    <div>
      <div className='w-100 container'>
        <div ref={reportRef}>
          <div className='container'>
            <div className="row" >
              <div className="col-12 mb-3">
                <h4 className="text-uppercase text-center fw-800">Rapport de caisse journalier</h4>
              </div>
              <div className="col-md-6 col-sm-6">
                
                <div>

                  {/* <div>
                      <h5>ÉTAT DE LA CAISSE PRINCIPALE</h5>
                  </div> */}
                  <table className="table table-responsive table-striped">
                    <tbody>

                      <tr className=''>
                        <td className='badge-soft-primary-force w-50 broder-bottom-1 text-uppercase'>
                        Solde Caisse Initial
                        </td>
                        <td className='broder-bottom-1'>{formatNumber(active_agency?.wallet?.initial_sold) ?? 0} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                      <tr className=''>
                        <td className='badge-soft-primary-force w-50 broder-bottom-1 text-uppercase'>
                        Chiffre d'affaires
                        </td>
                        <td className='broder-bottom-1'>{formatNumber(ca?.ca ?? 0)} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                      <tr>
                        <td className='badge-soft-primary-force w-50 text-uppercase' style={{whiteSpace: 'nowrap'}}>
                        Global des prestations
                        </td>
                        <td>{formatNumber(ca?.services_ca ?? 0)} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                      <tr>
                        <td className='badge-soft-primary-force w-50 text-uppercase'>
                        Global des produits
                        </td>
                        <td>{formatNumber(stats?.sold_products ?? 0)} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                      <tr>
                        <td className='badge-soft-primary-force w-50 text-uppercase'>Dépôts en Compte</td>
                        <td>{formatNumber(stats?.account_deposit ?? 0)} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                      <tr>
                        <td className='badge-soft-primary-force w-50 text-uppercase' style={{whiteSpace: 'nowrap'}}>Transf Autres Caisses</td>
                        <td>{formatNumber(stats?.transf_autres_caisses ?? "0")} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                      <tr>
                        <td className='badge-soft-primary-force w-50 text-uppercase'>{"Transf Recus coffre"}</td>
                        <td>{formatNumber(stats?.transfs_recus_coffre ?? 0)} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                      <tr>
                        <td className='badge-soft-primary-force w-50 text-uppercase'>Global des dépenses</td>
                        <td>{formatNumber(ca?.total_expenses ?? 0)} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                      {/* Total Cash percu */}
                      {/* {
                        stats && Object.entries(stats?.payment_methods).map(function([key, value], index) {
                        
                          if(["CASH"].includes(key)){

                            return(
                              <tr className='' key={"PM3-" + key}>
                                <td className='badge-soft-primary-force w-50 broder-bottom-1 text-uppercase'>
                                Total Cash perçu
                                </td>
                                <td className='broder-bottom-1'>{value ?? 0} <span className="text-muted font-size-16">FCFA</span></td>
                              </tr>
                            )
                          }
                        })
                      } */}
                      
                    </tbody>
                  </table>

                </div>
                <div>

                  <div>
                      <h5 className='text-uppercase'>Soldes Autres Caisses</h5>
                  </div>
                  <table className="table table-responsive table-striped">
                    <tbody>

                      {
                        wallets?.results?.map((wallet) => (

                      <tr className=''
                      key={'l' + wallet.id}>
                        <td className='badge-soft-danger-force w-50 broder-bottom-1 text-uppercase'>
                        {String(wallet?.mobile).replaceAll('_', ' ')}
                        </td>
                        <td className='broder-bottom-1'>{formatNumber(wallet?.amount ?? 0)} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                        ))
                      }
                    </tbody>
                  </table>

                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div>
                  {/* <div>
                      <h5>MÉTHODES DE RÈGLEMENT</h5>
                  </div> */}
                  <table className="table table-responsive table-striped">
                    <tbody>
                    {
                      stats && Object.entries(stats?.payment_methods).map(function([key, value], index) {
                        
                        if(!["NOT_DEFINED", "PG", "CASH", "VISsA_CARD"].includes(key)){

                          return(
                            <tr className='' key={"PM3-" + key}>
                              <td className='badge-soft-danger-force w-50 broder-bottom-1 text-uppercase'>
                              {String(t(key)).replaceAll("_", " ")}
                              </td>
                              <td className='broder-bottom-1'>{formatNumber(value ?? 0)} <span className="text-muted font-size-16">FCFA</span></td>
                            </tr>
                          )
                        }
                        })}
                        <tr>
                          <td className='badge-soft-danger-force w-50 text-uppercase'>PAIEMENTS À CRÉDIT</td>
                          <td>{formatNumber(stats?.credit_payment ?? 0) ?? 0} <span className="text-muted font-size-16">FCFA</span></td>
                        </tr>
                        <tr>
                          <td className='badge-soft-danger-force w-50 text-uppercase'>Transfert Coffre</td>
                          <td>{formatNumber(stats?.transfs_vers_coffre ?? 0) ?? 0} <span className="text-muted font-size-16">FCFA</span></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table className='table table-responsive table-striped'>
                    <tbody>
                      <tr className=''>
                        <td className='badge-soft-primary-force w-50 broder-bottom-1 text-uppercase text-black fs-5 fw-800' style={{fontWeight: '800'}}>
                        Solde en caisse
                        </td>
                        <td className='broder-bottom-1'>{formatNumber(active_agency?.wallet?.amount) ?? 0} <span className="text-muted font-size-16">FCFA</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                {/* <hr className='border-1'/> */}
                
                {/* <div className='row'>
                    {
                      stats && Object.entries(stats?.payment_methods).map(function([key, value], index) {
                            return(
                                <div className="col-xl-6 col-md-6 col-sm-6" key={"PM-" + key}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="avatar-md flex-shrink-0">
                        <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                          <span className="uim-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
                              <path
                                className="uim-primary"
                                d="M10,6V5h4V6h2V5a2.00229,2.00229,0,0,0-2-2H10A2.00229,2.00229,0,0,0,8,5V6Z"
                              ></path>
                              <path
                                className="uim-quaternary"
                                d="M9 15a.99974.99974 0 0 1-1-1V12a1 1 0 0 1 2 0v2A.99974.99974 0 0 1 9 15zM15 15a.99974.99974 0 0 1-1-1V12a1 1 0 0 1 2 0v2A.99974.99974 0 0 1 15 15z"
                              ></path>
                              <path
                                className="uim-tertiary"
                                d="M20,6H4A2,2,0,0,0,2,8v3a2,2,0,0,0,2,2H8V12a1,1,0,0,1,2,0v1h4V12a1,1,0,0,1,2,0v1h4a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Z"
                              ></path>
                              <path
                                className="uim-primary"
                                d="M20,13H16v1a1,1,0,0,1-2,0V13H10v1a1,1,0,0,1-2,0V13H4a2,2,0,0,1-2-2v8a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V11A2,2,0,0,1,20,13Z"
                              ></path>
                            </svg>
                          </span>
                        </span>
                      </div>
                      <div className="flex-grow-1 overflow-hidden ms-4">
                        <p className="text-muted text-truncate font-size-15 mb-2">{String(key).replaceAll("_", " ")}</p>
                        <h3 className="fs-4 flex-grow-1 mb-3">
                          {value ?? 0} <span className="text-muted font-size-16">FCFA</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                            )
                          })
                    }
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex'>
        <button
          type="button"
          className="btn mx-auto btn-primary waves-effect waves-light rounded-0 px-3"
          onClick={() => {handlePrint(); handleCloseCheckout();}}
        >
          {' '}
          Imprimer le Rapport
        </button>
      </div>
    </div>
  );
};

export default CloseCheckout;
