import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';
import useSWR from 'swr';
import { URLS } from '../../../services/urls';
import _ from 'lodash'

const InvoiceTicket = ({ invoice }) => {
  const [toShow, setToShow] = useState();
  const [dimensions, setDimensions] = useState({ width: 100, height: 500 });
  const { data: inv, isLoading, mutate } = useSWR(URLS.INVOICES.details(invoice?.id));

  const ticketRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: "",
    content: () => ticketRef?.current,
    documentTitle: 'Facture ' + inv?.reference,
  });

  useEffect(() => {
    var elem = document.getElementById("invoiceTicket");
    if(elem) {
    var rect = elem.getBoundingClientRect();
    setDimensions(prev => {
        return {
            "height": rect.width * 0.4645833,
            "width": 90,
        }
    })
    }
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-md-2 col-2"></div>
        <div className="col-md-8 col-8 text-center">
          <div className="d-flex">
            <div className="m-auto" style={{width: '80%'}}>
              <div className="mb-3 border-md-3 border p-3" ref={ticketRef} id='invoiceTicket'>
                <div className="row">
                  <div className="col-12">
                    <div className='text-center'>
                      <img
                        src="/assets/images/logo-dark.webp"
                        alt=""
                        height="100"
                        className=" mx-auto"
                      /> <br />
                      <p className="text-uppercase fw-bold text-center">{inv?.agency?.name}</p>
                      <p className="small mb-0 text-center">INSTITUT DE BIEN - ETRE - COIFFURES - SOINS CORPORELS - MASSAGE - ESPACE DETENTE</p>
                      <p className="small mb-0 text-center"><strong>RCCM</strong>: RC/DLN/2021/B/652 <strong>NIU</strong>: M022118505410B </p>
                        {/* <p className="mb-1 text-center">
                        <strong>Agence: </strong> {inv?.agency?.name} <br /> 
                      </p> */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="">
                      
                      <p className="mb-0 text-center small">
                        <strong>Client: </strong> {inv?.order?.customer?.first_name} {' '} {inv?.order?.customer?.last_name} {' '}
                        {/* <strong>Code client: </strong> {inv?.order?.customer?.reference} {' '} */}
                      <strong>Caissier: </strong>{' '}
                        {inv?.created_by?.first_name} {inv?.created_by?.last_name}
                      </p>
                    </div>
                  </div>
                  <div className="col-12  text-center">
                    <div>
                      <p className="mb-0 small text-center"> <strong>Date:</strong> {moment(inv?.created_at).format('DD/MM/YYYY HH:mm')} </p>
                      {/* <p className="mb-1 text-center">
                        <strong>Code Facture: </strong> {inv?.reference}
                      </p> */}
                      <br />
                      <h4 className='text-black fw-bold'>FACTURE</h4>
                    </div>
                    <hr style={{borderTop: "dotted 2px"}} className='my-2' />
                    <div>
                        {
                            inv?.order?.items?.map((item) => (
                                <div className="d-flex py-1">
                                    <span className='fw-normal'>{item?.item?.title}</span>
                                    <span className="ms-auto fw-normal">{item?.item?.price}</span>
                                </div>
                            ))
                        }
                    </div>
                    <hr style={{borderTop: "dotted 2px"}} className='my-0 mt-5'  />
                    <div className="py-1">
                        {/* <div className="d-flex py-1">
                            <span className='text-bold'>SOUS TOTAL</span>
                            <span className="ms-auto">{Math.round(inv?.sub_total) ?? 0}</span>
                        </div> */}
                        {inv?.discount_amount > 0 && <div className="d-flex py-1">
                            <span>Remise</span>
                            <span className="ms-auto">{Math.round(inv?.discount_amount ?? 0)}</span>
                        </div>}
                        <div className="d-flex py-1">
                            <span>TOTAL HT</span>
                            <span className="ms-auto">{Math.round(inv?.price_exclude_tax)}</span>
                        </div>
                        <div className="d-flex py-1">
                            <span>TVA (19.25%)</span>
                            <span className="ms-auto">{Math.round(inv?.tax_value)}</span>
                        </div>
                        <div className="d-flex py-1 fw-bold">
                            <h6 className='mb-0 fw-bold text-black'>TTC</h6>
                            <h6 className="ms-auto mb-0 fw-bold text-black">{Math.round(inv?.amount)}</h6>
                        </div>
                    </div>
                    <hr style={{borderTop: "dotted 2px"}} className='my-1' />
                    <div className="py-1">
                        {/* <div className="d-flex py-1">
                            <span>Encaissé</span>
                            <span className="ms-auto">{
                              sumArray(inv?.payments?.filter((i) => i.payment_method != "DETTE")?.map(i => parseInt(i?.amount)) ?? [])
                            }</span>
                        </div> */}
                        {/* {
                            inv?.amount_received - inv?.amount > 0 &&
                            (
                                <div className="d-flex py-1">
                                    <span>Rendu</span>
                                    <span className="ms-auto">{inv?.amount_received - inv?.amount}</span>
                                </div>
                            )
                        } */}
                        {/* {
                            _.filter(inv ?? [], {payment_method: "DETTE"}).length > 0 &&
                            (
                                <div className="d-flex py-1">
                                    <span>Dette</span>
                                    <span className="ms-auto">{_.filter(inv ?? [], {method: "DETTE"})[0]?.amount}</span>
                                </div>
                            )
                        } */}
                        <div className="d-flex py-1">
                            <span className='text-uppercase'>Mode de Règlement</span>
                            <span className="ms-auto">
                            <ul style={{listStyleType: "none", textAlign: "right"}}>
                              {inv?.payments?.map(
                                  (pm, idx) =>
                                    <li key={"pm-" + idx}>{String(pm.payment_method).replace('_', ' ') + ` (${pm.amount} FCFA)`}</li>
                                )}
                            </ul>
                            </span>
                        </div>
                    </div>
                    <hr style={{borderTop: "dotted 2px"}} className='my-1' />
                    <div>
                        <Barcode height={40} value={inv?.reference} width={1} />
                    </div>
                    <div className="d-flex flex-column">
                      <div className="mx-auto mt-3">
                        <p className="small mb-0 text-uppercase">Lundi-Dimanche: 7h30 - 21h</p>
                      </div>
                      <div className="mx-auto">
                        <p className="small text-uppercase">Merci pour votre visite et à très bientôt!</p>
                      </div>
                      <div className="ms-auto">
                        <span className="small">Imprimé le: {moment().format("DD/MM/YYYY HH:mm")}</span>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          <div>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={handlePrint}
            >
              {' '}
              Imprimer 
            </button>
          </div>
        </div>
        <div className="col-md-2 col-2"></div>
      </div>
    </div>
  );
};

export default InvoiceTicket;
