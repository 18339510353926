import { useEffect, useState } from "react";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls";
import { useSelector } from "react-redux";



export default function CustomerFromInvoice(props) {
    const { reference } = props;
    
    const { active_agency } = useSelector((state) => state.agencies);

    const [cutomerName, setCutomerName] = useState('');


    useEffect(() => {
        // console.log("active_agency ========> ", active_agency);
        if (active_agency) {

            const formattedReference = String(reference).split('-');

            API.get(URLS.SEARCH.invoices(active_agency?.id, formattedReference[formattedReference.length - 1], 1))
                .then((response) => {
                    setCutomerName(response.data?.results[0]?.customer);
                }).catch((error) => {
                    console.log(error);
                });
        }
    }, [active_agency]);

    return (
        <div>{cutomerName}</div>
    )
    
}