import React, { useState } from 'react';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import LoaderPage from '../../components/LoaderPage';
import ManageSupplyForm from './components/MaageSupplyForm';
import { formatDate, sumArray } from '../../components/utils/functions';
import SupplyTicket from './components/SupplyTocket';
import { DatePicker } from 'react-responsive-calendar-picker';

const SupplyLists = ({ type }) => {
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [toEdit, setToEdit] = useState();
  const [toEditStock, setToEditStock] = useState();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: products,
    isLoading,
    mutate,
  } = useSWR(
    URLS.STOCKS.lists(
      active_agency?.id,
      search,
      page,
      'IN',
      dates?.checkout
        ? `&created_at__gte=${formatDate(dates.checkin) + 'T00:00'}&created_at__lte=${
            formatDate(dates.checkout) + 'T00:00'
          }`
        : `&created_at=${formatDate(dates.checkin) + 'T00:00'}`
    )
  );

  const { t } = useTranslation();

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    setSelected(selectedRows);
  };

  const handleChangePage = (event, page) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleShow = (item, obj) => {
    let toShow = {};
    toShow[item] = obj;
    console.log('dlkfldf', toShow);
    setToEdit(toShow);
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.PRODUCTS.delete(obj.id), t, mutate);
  };

  return (
    <div className="row py-0">
      <div className="col-12">
        <div className="">
          <div className="card-body">
            <div className="d-flex justify-content-between flex-row">
              <div>
                <p className="text-uppercas h4">Approvisionnements du stock</p>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light rounded- px-3"
                  data-bs-toggle="modal"
                  data-bs-target=".new-stock-modal-in"
                >
                  {' '}
                  + Approvisionner
                </button>
              </div>
            </div>

            {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}

            {/* Manage stock */}

            <div
              className="modal fade new-stock-modal-in"
              tabIndex={-1}
              role="dialog"
              aria-labelledby={'myLargeModalLabel' + type}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id={'myLargeModalLabel' + type}>
                      {toEditStock
                        ? 'Modification de l\'approvisionnement Nº ' + toEditStock?.document_number
                        : 'Approvisionnement'}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <ManageSupplyForm
                      handleClose={handleClose}
                      onSuccess={handleSuccess}
                      toEdit={toEditStock}
                      side="IN"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Ticket */}
            <div
              className="modal fade stock-ticket-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby={'ticketDeatailsLargeModalLabel'}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id={'ticketDeatailsLargeModalLabel'}>
                      {"Détails de l'approvisionnement"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <SupplyTicket
                      handleClose={handleClose}
                      onSuccess={handleSuccess}
                      invoice={toEdit}
                      side="IN"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="row py-2">
                <div className="col-md-3 col-12">
                  <div>
                    <div className="form search-form">
                      <i className="fa fa-search"></i>
                      <input
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        class="form-control form-input py-2"
                        placeholder="Rechercher"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div>
                    <div className=" position-relative" style={{ zIndex: '1' }}>
                      <DatePicker
                        dates={dates}
                        setDates={setDates}
                        open={open}
                        setOpen={setOpen}
                        normal={true}
                      >
                        <button className="btn btn-primary rounded-" onClick={() => setOpen(!open)}>
                          Date du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                          {dates.checkout && 'au'}{' '}
                          {dates.checkout && dates.checkout.toLocaleDateString()}
                        </button>
                      </DatePicker>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-end">
                      <ExportExcel
                        label={'Exporter en Excel'}
                        sheetName={`Customers page: ${page}`}
                        columns={type === "SERVICES" ? exportServices : exportColumns}
                        dataToExport={(products?.results ?? []).map((customer) => {
                          return {
                            ...customer,
                            marge: `${customer.price - customer.buy_price}`,
                            created_by: `${customer?.created_by?.first_name} ${customer?.created_by?.last_name}`,
                          };
                        })}
                      />
                    </div> */}
                </div>
              </div>
              <hr className="mb-0" />
              <div className="table-responsive">
                <table className="table align-middle table-hover table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="text-uppercas">Date d'Achat</th>
                      <th className="text-uppercas">Nº Facture</th>
                      <th className="text-uppercas">Désignation</th>
                      {type != 'SERVICES' ? (
                        <>
                          <th className="text-uppercas">Quantité</th>
                          {/* <th className="text-uppercas">Prix d'achat</th> */}
                          <th className="text-uppercas">Prix d'Achat Unit</th>
                          <th className="text-uppercas">Prix Total</th>
                          {/* <th className="text-uppercas">N Document</th> */}
                          {/* <th className="text-uppercas">Marge</th>
                          <th className="text-uppercas">Emplacement</th> */}
                        </>
                      ) : (
                        <>
                          <th className="text-uppercas">Prix</th>
                        </>
                      )}
                      {/* <th className="text-uppercas">Stock</th> */}
                      <th className="text-uppercas">Date d'Approvisionnement</th>
                      <th className="text-uppercas">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(_.groupBy(products?.results ?? [], 'document_number')).map(
                      (item) => {
                        return (
                          <tr
                            key={'itm-' + item.id}
                            data-bs-toggle="modal"
                            onClick={() =>
                              handleShow(
                                item,
                                _.groupBy(products?.results ?? [], 'document_number')[item]
                              )
                            }
                            data-bs-target=".stock-ticket-modal"
                            style={{ cursor: 'pointer' }}
                          >
                            <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                            <td>{item}</td>
                            <td>
                              {_.groupBy(products?.results ?? [], 'document_number')[item]?.map(
                                (itm, index) => (
                                  <span key={'od-' + index} className="me-2">
                                    {itm?.product?.title},
                                  </span>
                                )
                              )}
                            </td>
                            <td>
                              {sumArray(
                                _.groupBy(products?.results ?? [], 'document_number')[item]?.map(
                                  (itm) => itm.quantity
                                )
                              )}
                            </td>
                            {/* <td>{Number(item.buy_price).toFixed(2)} FCFA</td> */}
                            <td>
                              {Number(
                                sumArray(
                                  _.groupBy(products?.results ?? [], 'document_number')[item]?.map(
                                    (itm) => parseFloat(itm.buy_price)
                                  )
                                )
                              ).toFixed(2)}{' '}
                              FCFA
                            </td>
                            <td>
                              {Number(
                                sumArray(
                                  _.groupBy(products?.results ?? [], 'document_number')[item]?.map(
                                    (itm) => parseFloat(itm.quantity) * parseFloat(itm.buy_price)
                                  )
                                )
                              ).toFixed(2)}{' '}
                              FCFA
                            </td>
                            {/* <td>
                          {item.new_quantity}
                        </td> */}
                            <td>
                              {moment(item.created_at).format('DD/MM/YYYY')}{' '}
                              <span className="ms-3">
                                {moment(item.created_at).format('HH:MM')}
                              </span>{' '}
                            </td>
                            <td>
                              <div className="d-flex gap-2 justify-content-center">
                                
                                <Link
                                  to={`#`}
                                  title="Afficher"
                                  data-bs-toggle="modal"
                                  onClick={() => handleShow(_.groupBy(products?.results ?? [], 'document_number')[item][0])}
                                  data-bs-target=".stock-ticket-modal"
                                  className="btn btn-warning btn-sm btn-colapsed"
                                >
                                  <i className="mdi mdi-eye"></i>
                                  <span>Afficher</span>
                                </Link>
                                <Link
                                  to="#"
                                  onClick={() => setToEditStock(_.groupBy(products?.results ?? [], 'document_number')[item])}
                                  title="Réapprovisionner"
                                  data-bs-toggle="modal"
                                  data-bs-target=".new-stock-modal-in"
                                  className="btn btn-primary btn-sm btn-colapsed d-none"
                                >
                                  <i className="ri-edit-line"></i>
                                </Link>
                            {/* <Link
                              to="#"
                              onClick={() => handleEdit(item)}
                              title="Modifier"
                              data-bs-toggle="modal"
                              data-bs-target=".new-product-modal"
                              className="btn btn-success btn-sm btn-colapsed"
                            >
                              <i className="mdi mdi-pencil"></i>
                              <span>Modifier</span>
                            </Link> */}
                                <Link
                              to="#"
                              title="Annuler"
                              onClick={() => handleDelete(item)}
                              className="btn btn-danger btn-sm btn-colapsed"
                            >
                              <i className="ri-close-circle-line"></i>
                            </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              {isLoading && <LoaderPage />}
              <div>
                {products?.count > 0 && (
                  <div className="paginations pt-3">
                    <ul className="pagination pagination-rounded">
                      <li>
                        <Link
                          tabIndex={-1}
                          onClick={(event) => handleChangePage(event, page - 1)}
                          className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                          to="#"
                        ></Link>
                      </li>
                      {new Array(Math.ceil(products.count / DEFAULT_PAGE_COUNT))
                        .fill(3)
                        .map((pag, index) => (
                          <li
                            key={'page' + index}
                            className={`page-item ${page === index + 1 ? 'active' : ''}`}
                            aria-current="page"
                          >
                            <Link
                              className={`pager-number ${
                                page * DEFAULT_PAGE_COUNT === Math.ceil(products.count)
                                  ? 'disabled'
                                  : ''
                              } ${page === index + 1 ? 'active' : ''}`}
                              to="#"
                              tabIndex={-1}
                              onClick={(event) => handleChangePage(event, index + 1)}
                            >
                              <span className="page-link">{index + 1}</span>
                            </Link>
                          </li>
                        ))}
                      <li>
                        <Link
                          tabIndex={-1}
                          className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                          to="#"
                        ></Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {/* <DataTable
                  // ref={tableRef}
                  columns={columns}
                  data={products?.results ?? []}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  progressPending={isLoading}
                  paginationServer
                  paginationTotalRows={products?.count ?? 0}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[20]}
                  paginationComponentOptions={paginationComponentOptions}
                /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplyLists;
