import Swal from "sweetalert2"
import { API } from "../../services/axios"
import { toast } from "react-toastify"

const confirmDeletion = (deletionUrl, t, onSuccess) => {

    Swal.fire({
        title: t("deletionAreYouSure"),
        text: t("deletionIrreversibleAlert"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t("deletionActionButton"),
        cancelButtonText: t("deletionActionCancel"),
        showLoaderOnConfirm: true,
        timerProgressBar: true,

      }).then((result) => {
        if (result.isConfirmed) {
            const id = toast.loading(t("deletionProcess"))
            API.delete(deletionUrl).then((resp) => {
                Swal.fire(
                  t("deletionDeleted"),
                  t("deletionSuccessMessage"),
                  'success'
                );
                onSuccess()
                toast.update(id, {render: t("deletionSuccessMessage"), type: "success", isLoading: false, autoClose:true});
            }).catch(error => {
                toast.update(id, {render: t("requestGlobalErrorMessage"), type: "error", isLoading: false, autoClose:true });
            })
        }
      })
}


const confirmCancelation = (deletionUrl, t, onSuccess) => {

  Swal.fire({
      title: t("cancellationAreYouSure"),
      text: t("cancellationIrreversibleAlert"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t("cancellationActionButton"),
      cancelButtonText: t("cancellationActionCancel"),
      showLoaderOnConfirm: true,
      timerProgressBar: true,

    }).then((result) => {
      if (result.isConfirmed) {
          const id = toast.loading(t("cancellationProcess"))
          API.delete(deletionUrl).then((resp) => {
              Swal.fire(
                t("cancellationDeleted"),
                t("cancellationSuccessMessage"),
                'success'
              );
              onSuccess()
              toast.update(id, {render: t("cancellationSuccessMessage"), type: "success", isLoading: false, autoClose:true});
          }).catch(error => {
              toast.update(id, {render: t("requestGlobalErrorMessage"), type: "error", isLoading: false, autoClose:true });
          })
      }
    })
}

export { confirmDeletion, confirmCancelation }