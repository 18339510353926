


const PdfHeader = ({documentTitle}) => {

    return <div>
        <div className="row mb-4">
            <div className="col-5">
                <div>
                    <img src="/assets/images/logo-dark.webp" alt="" height="100" class="" />
                </div>
            </div>
            <div className="col-7">
                <div className="border-1 border p-2 text-start">

                    <span className="">INSTITUT DE BIEN - ETRE - COIFFURES - SOINS CORPORELS - MASSAGE - ESPACE DETENTE</span>
                    {/* <hr /> */}
                    <br />
                    <strong>RCCM</strong>: RC/DLN/2021/B/652 <br /> <strong>NIU</strong>: M022118505410B 
                </div>
            </div>
            <div className="col-12 mt-4">
                {
                    documentTitle && <div className="text-center">
                        <h1 className="text-uppercase">{documentTitle}</h1>
                    </div>
                }
            </div>
        </div>
    </div>

}


export default PdfHeader;