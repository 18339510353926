export const DEFAULT_PAGE_COUNT = 25;

export const DEFAULT_IMAGE = "/assets/images/avatar.jpg";
export const DEFAULT_PROFILE = "/assets/imgs/avatar/avatar.png";

export const APPLICATION_STEPS = [
    "sent",
    "in_progress",
    "interview",
    "test",
    "hr",
    "waiting_user",
    "accepted",
    "rejected",
]

// CAISSE PRINCIPALE
export const TYPES_OF_BUY = [
    { value: "ACHAT_DE_CARBURANT", label: "Achat de carburant" },
    { value: "AUTRES_ACHATS", label: "Autres achats " },
    { value: "ENTRETIENS_MAINTENANCE_ET_REPARATIONS", label: "Entretiens, maintenance et réparations" },
    // { value: "FOURNITURE_DE_BUREAU", label: "Fournitures de bureau " },
    { value: "MOBILIER_DE_BUREAU", label: "Mobilier de bureau " },
    { value: "PRESSING", label: "Pressing" },
    { value: "PRODUITS_SOINS_ET_COIFFURE", label: "Produits soins et coiffures " },
    { value: "PRODUITS_D_ENTRETIEN", label: "Produits d’entretien" },
    { value: "TRANSFERT_DE_FONDS", label: "Transfert de fonds " },
    { value: "TRANSFERT_ET_DEPLACEMENT", label: "Transport et déplacement " },
    { value: "TRAVAUX_DIVERS", label: "Travaux divers" },
    { value: "", label: "" },
];

// Depenses de la caisse
export const TYPES_OF_EXPENSE = [
    { value: "TRANSFERT_DE_FONDS", label: "Transfert de fonds " },
    { value: "", label: "" },
];

// COFFRE 
export const TYPES_OF_BUY_COFFRE = [
    { value: "CNPS_IMPOTS_ET_TAXES", label: "CNPS, Impots et taxes " },
    { value: "PRODUITS_SOINS_ET_COIFFURES", label: "Produits soins et coiffures" },
    { value: "TRANSPORT", label: "Transport" },
    { value: "SALAIRES", label: "Salaires" },
    { value: "TRANFERT_TO_MAIN_CHECKOUT", label: "Transfert vers la caisse principale" },
    { value: "AUTRES", label: "Autres" },
];

// Mobile payment methods

export const MOBILE_PAYMENT_METHODS = ["OM", "MOMO", "DEBIT_CARD"]

export const OTHERS_TRANSACTIONNS_TYPES = ["AUTRES", "AUTRES_ACHATS", "CUSTOM_REASON"]
export const DOCUMENTS_TYPES = [
    {
        name: "CNI",
        label: "CNI",
    },
    {
        name: "PLAN_DE_LOCALISATION",
        label: "PLAN DE LOCALISATION",
    },
    {
        name: "CV",
        label: "CV",
    }
]

export const OPENING_HOURS = [
    8,9,10,11,12,13,14,15,16,17,18,19
]

export const getHoursList = () => {
    let hours = [
        "08:00", "08:15", "08:30", "08:45",
        "09:00", "09:15", "09:30", "09:45",
        "10:00", "10:15", "10:30", "10:45",
        "11:00", "11:15", "11:30", "11:45",
        "12:00", "12:15", "12:30", "12:45",
        "13:00", "13:15", "13:30", "13:45",
        "14:00", "14:15", "14:30", "14:45",
        "15:00", "15:15", "15:30", "15:45",
        "16:00", "16:15", "16:30", "16:45",
        "17:00", "17:15", "17:30", "17:45",
        "18:00", "18:15", "18:30", "18:45",
        "19:00", "19:15", "19:30",
]
    // OPENING_HOURS.forEach((value) => {
    //     for (let i = 1; i < 5; i++) {
    //         hours.concat(value + " : " + i*15)
    //     }
    // })

    return hours
}

export const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // backgroundColor: isDisabled ? 'red' : blue,
        textTransform: 'uppercase',
        cursor: isDisabled ? 'not-allowed' : 'default',
      }; 
    },
  };

export const ROLE_ADMIN = ['ADMIN']
export const ROLE_CHEF_AGENCE = ['ADMIN', 'CHEF_AGENCE']
export const ROLE_RESPONSABLE_EXPLOITATION = ['ADMIN', 'RESPONSABLE_EXPLOITATION']
export const ROLE_CHEF_SALLE = ['CHEF_SALLE', 'CHEF_AGENCE', 'OP_CAISSE']
export const ROLE_OP_CAISSE = ['OP_CAISSE']
export const ROLE_EMPLOYEE = ['EMPLOYEE','ADMIN', 'CHEF_SALLE', 'CHEF_AGENCE']
export const ROLE_MANAGEMENT = ['RESPONSABLE_EXPLOITATION', 'CHEF_AGENCE', 'OP_CAISSE']

export const PAYMENT_METHODS = [
    {
        "code": "OM",
        "name": "Orange Money",
        "img": "/assets/images/om-logo.jpeg"
    },
    {
        "code": "MOMO",
        "name": "Mobile Money",
        "img": "/assets/images/momo-logo.png"
    }
]