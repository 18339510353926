import { useEffect, useRef, useState, useTransition } from 'react';
import { useForm } from 'react-hook-form';
import CLoader from '../../../components/Loader';
import { useSelector } from 'react-redux';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import Select from 'react-select';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  formatLinkString,
  getProductOrServiceType,
  handleFocus,
  handlePrintInvoice,
  sumArray,
} from '../../../components/utils/functions';
import InvoiceTicket from './InnvoiceTicket';
import { ROLE_RESPONSABLE_EXPLOITATION } from '../../../components/utils/consts';

const  NewInvoiceForm = ({ toEdit, onSuccess, handleClose, productType, customerId2, handleDisplayInvoice, chooseCustomer, title }) => {
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');
  const [searchProduct, setSearchProduct] = useState('');
  const [searchEmployers, setSearchEmployers] = useState('');
  const [searchCoupon, setSearchCoupon] = useState('');
  const [searchCustomers, setSearchCustomers] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [selectedService, setSelectedService] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [customer, setCustomer] = useState();
  const [changingStatus, setChangingStatus] = useState('');
  const [serviceOrProduct, setServiceOrProduct] = useState('SERVICE');
  const [confirmingAppointmennt, setConfirmingAppointmennt] = useState(false);

  // Payment methods
  const [payments, setPayments] = useState([]);
  const [payment, setPayment] = useState({method: "", amount: 0,});

  const [addingToCart, setAddingTocart] = useState(false);
  const [cart, setCart] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  const { auth } = useSelector((state) => state.auth);
  const { active_agency } = useSelector((state) => state.agencies);

  const { customerId } = useParams();

  const { data: employees, isLoading: loadingEmployees } = useSWR(
    URLS.USERS.employees(active_agency?.id, searchEmployers, 1)
  );
  const {
    data: services,
    isLoading,
    mutate,
  } = useSWR(
    URLS.PRODUCTS.list(
      active_agency?.id,
      searchProduct,
      1,
      serviceOrProduct ?? productType ?? 'SERVICE'
    )
  );

  const { data: customers, isLoading: loadingCustomers } = useSWR(
    URLS.CUSTOMERS.list(active_agency?.id, searchCustomers, 1, '&type=SERVICE')
  );

  const { data: client, isLoading: loadingClient } = useSWR(
    URLS.CUSTOMERS.details(customerId ?? customerId2)
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleComfirmAppointment = (id) => {
    const id2 = toast.loading('Confirmationn en cours');
    // MArk an appointment as started
    setConfirmingAppointmennt(true);
    API.put(URLS.APPOINTMENTS.mark_as_started(id))
      .then((resp) => {
        setConfirmingAppointmennt(false);
        toast.update(id2, {
          render: 'Rendez-vous confirmé',
          type: 'success',
          isLoading: false,
          autoClose: true,
        });
      })
      .catch((error) => {
        setConfirmingAppointmennt(false);
        toast.update(id2, {
          render: error?.response?.data?.detail ?? t('requestGlobalErrorMessage'),
          type: 'error',
          isLoading: false,
          autoClose: true,
        });
      });
  };

  const handleChangeInvoiceStatus = (id, status) => {
    setChangingStatus(status);
    const id2 = toast.loading('Changement de statut en cours...');
    // MArk an appointment as Cancelled or completed
    API.patch(URLS.INVOICES.update(id), {
      status: status,
      payment_method: getValues('paymentMethod'),
    })
      .then((resp) => {
        if (onSuccess) {
          onSuccess();
        }
        reset();
        setChangingStatus('');
        toast.update(id2, {
          render:
            status === 'CANCELLED'
              ? 'La facture a bien été annulée!'
              : 'La facture a bien été marquéé comme payéé!',
          type: 'success',
          isLoading: false,
          autoClose: true,
        });
        if (handleClose) {
          handleClose();
        }
      })
      .catch((error) => {
        setChangingStatus('');
        toast.update(id2, {
          render: "Une erreur s'est produite, veuiller reéssayer",
          type: 'error',
          isLoading: false,
          autoClose: true,
        });
      });
  };

  const handlePay = (orderId, status) => {
    setChangingStatus(status);

    setTimeout(async () => {
      for(const element of payments)
        {
          await API.post(URLS.CART.ORDERS.pay, {
            "payment_method": element.method,
            "amount": element.amount,
            "customer":  customerId ?? selectedCustomer?.value ?? customerId2,
            "orderr": orderId,
          }).then((resp) => {
            setPayments([]);
            setPayment({
              method: "",
              amount: ""
            });
            setServiceOrProduct(productType);
            if(resp.data?.success){

              window.$('.details-invoice-modal')?.modal('show');
              toast.success("La facture a bien été marquée comme payéé.");
              handlePrintInvoice(orderId);
              setChangingStatus("");
            }
            if (handleClose) {
              handleClose()
            }
            
          }).catch((error) => {
            setChangingStatus("");
          })
        };

    }, 1000)


  }

  const onSubmit = (data) => {
    if (toEdit?.status === 'COMPLETED') {
      toast.error('La facture a déja été encaisséé et ne peut donc plus etre modifiéé !');
    } else if (cart.length > 0) {
      let toSend = {
        amount: 0,
        payment_method: "CASH",
        status: "COMPLETED",
        agency: active_agency.id,
        created_by: auth?.user?.id,
        coupon: selectedCoupon?.value ?? undefined,
        amount_received: 0,
        invoice_rest: 0,
        // Faire un approvisionnement de compte avec le reste d'argent
        deposit_with_rest: false,
        // Enregistrer comme une dette du client
        custumer_credit: false,
      };

      if (toEdit) {
        setLoading(true);
        API.patch(URLS.INVOICES.update(toEdit.id), toSend)
          .then((resp) => {
            if (onSuccess) {
              onSuccess(resp.data);
            }
            toast.success('Le facture a bien été modifiée');
            setLoading(false);
            if(handleDisplayInvoice){
              handleDisplayInvoice(resp.data)
            }
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.detail ??
                "Une erreur s'est produite lors de la modification de la facture, veuiller reésayer !"
            );
            setLoading(false);
          });
        // Update order
        const orderData = {
          ref_code: 'string',
          ordered: true,
          received: false,
          refund_requested: false,
          refund_granted: false,
          items: cart.map((item) => item.id),
          order: toEdit?.id,
          coupon: selectedCoupon?.value ?? undefined,
        };
        API.patch(URLS.CART.ORDERS.update(toEdit?.order?.id), orderData)
          .then((resp) => {
            toast.success('La commande a bien été modifiéé');
            setLoading(false);
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.detail ??
                "Une erreur s'est produite lors de la modification de la commande, veuiller reésayer !"
            );
            setLoading(false);
          });
          // handlePay(toEdit?.order?.id)
      } else {
        // On submit, create the order first
        const orderData = {
          ref_code: 'string',
          ordered_date: moment(),
          ordered: true,
          received: false,
          refund_requested: false,
          refund_granted: false,
          customer: customerId ?? selectedCustomer?.value ?? customerId2,
          items: cart.map((item) => item.id),
          coupon: selectedCoupon?.value ?? undefined,
        };
        setLoading(true);
        API.post(URLS.CART.ORDERS.create, orderData).then((resp) => {
          toSend['order'] = resp.data.id;

          handlePay(resp?.data?.id);
          API.post(URLS.INVOICES.create, toSend)
          .then((resp) => {
            if (onSuccess) {
              onSuccess(resp.data);
            }
            if (handleClose) {
              handleClose();
            }
            toast.success('La facture a bien été créée');
            setLoading(false);
            reset();
            setCart([]);
            setCartItems([]);
            if(handleDisplayInvoice){

              handleDisplayInvoice(resp.data);
            }
            })
            .catch((error) => {
              toast.error(
                error?.response?.data?.detail ??
                  "Une erreur s'est produite lors de la création de la facture, veuiller reésayer !"
              );
              setLoading(false);
            });
        });
      }
      console.log(data);
    } else {
      toast.error('Veuiller rajouter un ou plusieurs services');
    }
  };

  const handleRemoveItem = (itemId) => {
    API.delete(URLS.CART.ITEM.delete(itemId))
      .then((resp) => {
        setCartItems((prev) => {
          const newCart = cart.filter((itm) => itm.id == itemId);
          const newCartItems = prev.filter((itm) => itm != newCart[0]?.item?.id);
          return newCartItems;
        });
        setCart((prev) => {
          const newCart = prev.filter((itm) => itm.id != itemId);
          return newCart;
        });
      })
      .catch((error) => {});
  };

  const isDisabled = (meth) => {
    return _.filter(payments, {method: meth})?.length > 0;
  }

  const handleEditPayment = (payment) => {

    setPayment({
      method: payment?.method ?? "",
      amount: payment?.amount ?? "",
    });
    handleDeletePayment(payment.id);
  }

  const handleDeletePayment = (payment) => {
    console.log("payment==============>")
      API.delete(URLS.PAYMENTS.delete(payment.id)).then((resp) => {
        setPayments(
          (prev) => prev.filter((item) => item.id != payment)
        )
      }).catch ((error) => {
      
      setPayments(
        (prev) => prev.filter((item) => item.id != payment)
      )
    })
  }

  const handleAddPayment = (event) => {
    event.preventDefault()
    if(payment?.method && payment?.amount) {
      if(payment?.method === "SOLDE_DISPONIBLE") {
        // Traitement specifique aux clients en compte
        if (client?.wallet?.amount <= 0) {
          toast.error("Impossible de payer la facture, veuiller approvisionner le compte");
        } else if(client?.wallet?.amount < payment?.amount) {
          setPayments(
            (prev) => [{
              id: _.uniqueId(),
              method: payment?.method ?? "",
              amount: client?.wallet?.amount ?? "",
            }, ...prev]
          );
          setPayment({
            method: "",
            amount: "",
          })
        } else {
          setPayments(
            (prev) => [{
              id: _.uniqueId(),
              method: payment?.method ?? "",
              amount: payment?.amount ?? "",
            }, ...prev]
          );
          setPayment({
            method: "",
            amount: "",
          })
        }
      } else {

        setPayments(
          (prev) => [{
            id: _.uniqueId(),
            method: payment?.method ?? "",
            amount: payment?.amount ?? "",
          }, ...prev]
        );
        setPayment({
          method: "",
          amount: "",
        })
      }
    }
  }

  const handleUpdateQuantity = (itemId, quantity) => {
    // Update product item
    if (quantity >= 1) {
      API.patch(URLS.CART.ITEM.update(itemId), { quantity })
        .then((resp) => {
          setCart((prev) => {
            const newCart = prev.map((itm) => {
              if (itm.id === itemId) {
                itm.quantity = resp.data.quantity;
              }
              return itm;
            });
            return newCart;
          });
        })
        .catch((error) => {});
    }
  };

  const handleAddToCart = (event) => {
    event.preventDefault();
    console.log(selectedEmployee && selectedService);
    if (!selectedCustomer && !customerId && !customerId2) {
      toast.error("Veuiller choisir un client, s'il vous plait !");
    } else if (selectedEmployee && selectedService) {
      setAddingTocart(true);

      const data = {
        ordered: true,
        quantity: quantity ?? 1,
        customer: customerId ?? selectedCustomer?.value ?? customerId2,
        item: selectedService?.value,
        executor: selectedEmployee?.value,
      };
      API.post(URLS.CART.ITEM.create, data)
        .then((resp) => {
          setAddingTocart(false);
          // toast.success("")
          setQuantity(1);
          // setSelectedEmployee({label:'', value: undefined});
          setSelectedService({label:'', value: undefined});
          setSearchEmployers('');
          setSearchProduct('');
          setCart((prev) => [resp.data, ...prev]);
          setCartItems((prev) => [String(resp.data?.item?.id), ...prev]);
        })
        .catch((error) => {
          setAddingTocart(false);
        });
    }
  };

  const filterServices = (servics) => {
    const coiffures = cart?.filter((item) => {
      if(String(item?.item?.cat?.name).toUpperCase() === "COIFFURES"){
        return item;
      }
    })
    if(coiffures?.length > 0) {
      let newItems = servics?.filter((item) => {
        if(String(item?.cat?.name).toUpperCase() === "COIFFURES"){
          return item;
        }
      })
      return newItems;
    } else {
      return servics;
    }
  };

  const handleUpdatePrice = (itemId, item_price) => {
    // Update product item
    if (quantity >= 1) {
      API.patch(URLS.CART.ITEM.update(itemId), { item_price })
        .then((resp) => {
          setCart((prev) => {
            const newCart = prev.map((itm) => {
              if (itm.id === itemId) {
                itm.item_price = resp.data.item_price;
                itm.final_price = resp.data.item_price;
              }
              return itm;
            });
            return newCart;
          });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail ?? "Impossible de modifier le prix.")
        });
    }
  };

  useEffect(() => {
    if (toEdit) {
      reset({
        amount: toEdit?.amount,
        status: toEdit?.status,
        paymentMethod: toEdit?.payment_method,
        amountReceived: toEdit?.amount_received,
        invoiceRest: toEdit?.invoice_rest,
        depositWithRest: toEdit?.deposit_with_rest,
        customerCredit: toEdit?.custumer_credit,
      });
      setCart(toEdit?.order?.items ?? []);
      setCartItems(toEdit?.order?.items?.map((item) => String(item?.item?.id)) ?? []);
      setSelectedCustomer({
        value: toEdit?.order?.customer?.id,
        label: `${toEdit?.order?.customer?.first_name} ${toEdit?.order?.customer?.last_name}`,
      });
      if (toEdit?.order?.coupon) {
        setSelectedCoupon({
          value: toEdit?.order?.coupon?.id,
          label: `${toEdit?.order?.coupon?.code} (${toEdit?.order?.coupon?.amount} FCFA )`,
        });
      }

      // If payments methods
      if(toEdit?.payments){
        setPayments(toEdit?.payments)
      }
    }
  }, [toEdit]);

  useEffect(() => {
    if (cart) {
      reset({
        amount: sumArray(cart.map((item) => item.final_price)),
      });
      setCartItems(cart?.map((item) => item?.item?.id) ?? []);
    }
  }, [cart]);

  useEffect(() => {
    if (selectedService && selectedService?.value) {
      setServiceOrProduct(getProductOrServiceType(services?.results, selectedService?.value));
    }
  }, [selectedService]);

  useEffect(() => {
    const customer = _.filter(customers?.results, {
      id: selectedCustomer?.value ?? customerId ?? customerId2,
    });
    if (customer?.length > 0) {
      setCustomer(customer[0]);
    }
  }, [customerId2, customerId, selectedCustomer]);

  useEffect(() => {
    if (watch('depositWithRest')) {
      setValue('customerCredit', false);
    }
    if (watch('customerCredit')) {
      setValue('depositWithRest', false);
    }
  }, [watch('depositWithRest'), watch('customerCredit')]);

  useEffect(() => {
    // setPa
    console.log(sumArray(cart.map((item) => item.final_price)), (sumArray(payments.map((item) => parseFloat(item.amount)) ?? 0)))
    setPayment({
      amount: sumArray(cart.map((item) => item.final_price)) - (sumArray(payments.map((item) => parseFloat(item.amount))) ?? 0),
      method: payment.method
    })
    
  }, [cart, payments]);

  // useEffect(() => {
  //   window.$('.details-invoice-modal').modal('toggle');
  // }, [])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id="newInnvoiceSubmit">
        {/* {JSON.stringify(cart)} */}
        
        {title && <div>
          <p className="text-uppercase h4">{title}</p>
          </div>}
        <div className="row">
          <div className="py-3 col-12">
            <div>
              {/* <h3>Désignations</h3>÷ */}
              {/* <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <p className="mb-0">Vous avez {cart.length} éléments dans le panier</p>
                </div>
              </div> */}

              <div className="row mb-3 pt-3">
                {/* <div className="col-12">
                  <div>
                    <p>Ajouter une désignation</p>
                  </div>
                </div> */}
                {
                  !toEdit &&
                <div className="col-md-4 col-12">
                  <label htmlFor="#">
                    Désignation <span className="text-danger">*</span>
                  </label>
                  
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    value={selectedService}
                    // isClearable={true}
                    inputValue={searchProduct}
                    // isSearchable={true}
                    onChange={(newValue) => setSelectedService(newValue)}
                    onInputChange={(value) => setSearchProduct(value)}
                    name="prestation"
                    options={filterServices(services?.results)?.map((service) => {
                      return {
                        value: service.id,
                        label: String(service.title).toUpperCase() ,
                        isDisabled: cartItems.includes(service.id),
                      };
                    })}
                  />
                </div>
                }
                {serviceOrProduct != "ALL" && (
                  <div className="col-md-4 col-12">
                    <label htmlFor="#">
                      {getProductOrServiceType(services?.results, selectedService?.value) ===
                      'PRODUCT'
                        ? 'Vendeur'
                        : 'Opérateur'}{' '}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isLoading={loadingEmployees}
                      inputValue={searchEmployers}
                      // isClearable={true}
                      // isSearchable={true}
                      value={selectedEmployee}
                      onChange={(newValue) => setSelectedEmployee(newValue)}
                      onInputChange={(value) => setSearchEmployers(value)}
                      name="prestataire"
                      options={employees?.results?.map((employee) => {
                        return {
                          value: employee.id,
                          label: `${employee.first_name} ${employee.last_name}`,
                        };
                      })}
                    />
                  </div>
                )}
                {getProductOrServiceType(services?.results, selectedService?.value) ===
                  'PRODUCT' && (
                  <div className="col-md-3 col-12">
                    <div className="mb-4">
                      <label htmlFor="#">
                        Quantité <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        type="number"
                        placeholder="Quantite"
                        onFocus={handleFocus}
                      />
                    </div>
                  </div>
                )}
                {!toEdit && <div className="col-md-1 col-12 pt-1">
                  <label htmlFor="#"></label>
                  <label htmlFor="#"></label>
                  <div className="mt-1">
                    <Link
                      to="#"
                      className="btn btn-success btn-md btn-colapsed h-100"
                      onClick={handleAddToCart}
                    >
                      {addingToCart ? <CLoader show={true} /> : <i className="mdi mdi-check"></i>}
                    </Link>
                  </div>
                </div>}
              </div>
              <div>
                {cart.length > 0 &&<div className="table-responsive">
                  <table className="table mb-0 table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>Référence</th>
                        <th>Désignation</th>
                        <th>
                          {getProductOrServiceType(services?.results, selectedService?.value) ===
                          'PRODUCT'
                            ? 'Vendeur'
                            : 'Opérateur'}
                        </th>
                        <th>Quantité</th>
                        <th>Prix Unitaire</th>
                        <th>Montant</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart?.map((item) => (
                        <tr key={'k' + item?.id}>
                          <th scope="row" className='py-1'>{item.item?.reference}</th>
                          <td className='py-1'>{item.item?.title}</td>
                          <td className='py-1'>{item?.executor?.first_name} {item?.executor?.last_name}</td>
                          <td className='py-1'>
                            <input type="number" onChange={(e) => handleUpdateQuantity(item.id, e.target.value)} className='m-0 p-0 border-0' style={{width: '30px'}} defaultValue={item?.quantity} onFocus={handleFocus} />
                          </td>
                          <td className='py-1'>
                            <input type="number" onChange={(e) => handleUpdatePrice(item.id, e.target.value)} className='m-0 p-0 border-0' style={{width: '50px'}} defaultValue={item?.item_price} onFocus={handleFocus} />
                          </td>
                          <td className='py-1'>{item.quantity * item?.item_price}</td>
                          <td className='py-1'>
                          <Link
                              to="#"
                              className="btn btn-sm text-danger btn-colapsed p-0"
                              onClick={() => handleRemoveItem(item.id)}
                            >
                              {addingToCart ? <CLoader show={addingToCart} /> : <i className="mdi mdi-delete"></i>}
                            </Link>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={5}></td>
                        <td colSpan={2}>
                          <span>
                            <strong>TOTAL: </strong> {" "}
                            {sumArray(cart.map((item) => item.final_price))}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>}
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div>
              <div className="row">
                <div className="col-md-4"></div>
                {cart.length > 0 && <div className="col-md-8 col-12">
                  <div className="row">
                    <div className="col-md-auto">
                      <div className="mb-3">
                        <label htmlFor="paymentMethod">
                          Mode de Règlement <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          id="paymentMethod"
                          // {...register('paymentMethod', { required: true })}
                          aria-label="Default select example"
                          value={payment?.method ?? ""} onChange={(e) => setPayment((prev) => {
                            return {...prev, method: e.target.value};
                          })}
                        >
                          <option value="">Choisir</option>
                          {/* <option value="NOT_DEFINED">Non specifie</option> */}
                          <option value="CASH" disabled={isDisabled("CASH")}>CASH</option>
                          <option value="OM" disabled={isDisabled("OM")}>ORANGE MONEY</option>
                          <option value="MOMO" disabled={isDisabled("MOMO")}>MOBILE MONEY</option>
                          <option value="DEBIT_CARD" disabled={isDisabled("DEBIT_CARD")}>DEBIT CARD</option>
                          <option value="SOLDE_DISPONIBLE" disabled={isDisabled("SOLDE_DISPONIBLE")}>
                            SOLDE EN COMPTE{' '}
                            {customers?.results?.filter(
                              (item) =>
                                item.id === (selectedCustomer?.value ?? customerId2 ?? customerId)
                            ).length > 0 &&
                              `(${
                                customers?.results?.filter(
                                  (item) =>
                                    item.id === (selectedCustomer?.value ?? customerId2 ?? customerId)
                                )[0]?.wallet?.amount
                              } XAF)`}
                          </option>
                          <option value="CREDIT" disabled={isDisabled("CREDIT")}>CREDIT</option>
                          <option value="OFFER" disabled={isDisabled("OFFER")}>OFFRIR</option>
                        </select>
                        {watch('paymentMethod') === 'SOLDE_DISPONIBLE' &&
                          watch('amount') >
                            customers?.results?.filter(
                              (item) =>
                                item.id === (selectedCustomer?.value ?? customerId2 ?? customerId)
                            )[0]?.wallet?.amount && (
                            <span className="text-danger small">
                              Le montant de la facture est supérieur au solde du compte, veuiller
                              l'approvisionner
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="col-md-auto">
                    <div className="mb-3">
                        <label htmlFor="paymentMethod">
                          Montant <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex h-100">
                          <div>

                          <input type="number" min={0} className="form-control" value={payment?.amount} onChange={(e) => setPayment((prev) => {
                            return {...prev, amount: e.target.value};
                          })} onFocus={handleFocus}/>
                          </div>
                          <div className="mb-3 ms-2 h-100">
                            <Link
                              to="#"
                              className="btn btn-success btn-md btn-colapsed h-100"
                              onClick={handleAddPayment}
                            >
                              <i className="mdi mdi-check"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-auto">
                    </div>
                  </div>
                  <div className="">
                    <div>
                      {
                        payments?.map((item) => (
                          <div className="d-flex justify-content-end" key={"payment-" + item.id}>
                            <div className="ms-2">{(item?.method ?? item?.payment_method) !== "SOLDE_DISPONIBLE" ? (item?.method ?? item?.payment_method) : "SOLDE EN COMPTE"}</div>
                            <div className="ms-2">{item?.amount} FCFA</div>
                            <div className="ms-2">
                              <Link to="#" onClick={() => handleEditPayment(item)}>Modifier</Link> 
                              {/* {" "}|{" "}
                              <Link to="#" onClick={() => handleDeletePayment(item)}>Supprimer</Link> */}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>}

                {chooseCustomer && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <div className="mb-3">
                          <label htmlFor="customer">
                            Client <span className="text-danger">*</span>{' '}
                          </label>
                          <Select
                            className="basic-single-customer"
                            classNamePrefix="select"
                            isLoading={loadingCustomers}
                            value={selectedCustomer}
                            isClearable={true}
                            inputValue={searchCustomers}
                            isSearchable={true}
                            onChange={(newValue) => setSelectedCustomer(newValue)}
                            onInputChange={(value) => setSearchCustomers(value)}
                            name="customer"
                            options={customers?.results?.map((service) => {
                              return {
                                value: service.id,
                                label: `${service?.first_name} ${service?.last_name}`,
                              };
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          { (
            <div>
              <div className="button-items d-flex">
                <div className="d-flex flex-column">

              <button type="submit" className="btn mb-3 btn-primary w-md waves-effect waves-light" disabled={sumArray(payments.map((item) => item.amount)) < sumArray(cart.map((item) => item.final_price)) || loading}>
            <span className="my-auto">{toEdit ? 'Modifier' : 'Enregistrer'} la facture</span>{' '}
            <span className="ms-2 d-inline-flex">
            <i className="ri-save-line align-middle ms-2"></i>{' '}
              {' '}
              <CLoader size={20} show={loading} />{' '}
            </span>
          </button>
                {(toEdit?.status === 'PENDING' && ROLE_RESPONSABLE_EXPLOITATION.includes(auth?.user?.user_level)) && (
                  <button
                    disabled={changingStatus === 'CANCELLED'}
                    onClick={() => handleChangeInvoiceStatus(toEdit.id, 'CANCELLED')}
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                  >
                    <span className="my-auto">Annuler la Facture</span>{' '}
                    <span className="ms-2 d-inline-flex">
                      <i className="ri-close-line align-middle me-2"></i>{' '}
                      <CLoader show={changingStatus === 'CANCELLED'} />
                    </span>
                  </button>
                )}
                </div>
                <div></div>
                {toEdit?.status === 'PENDING' && (
                  <button
                    disabled={sumArray(payments.map((item) => item.amount)) < sumArray(cart.map((item) => item.final_price)) || (changingStatus === 'COMPLETED')}
                    onClick={() => handlePay(toEdit?.order?.id, 'COMPLETED')}
                    type="button"
                    className="btn btn-success waves-effect waves-light ms-auto my-auto"
                  >
                    <span className="my-auto"> Payer la Facture </span>{' '}
                    <span className="ms-2 d-inline-flex">
                      <i className="ri-check-line align-middle ms-2"></i>{' '}
                      <CLoader show={changingStatus === 'COMPLETED'} />
                    </span>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="button-items  d-inline-flex">
          <button className="hidden" hidden id="submitInvoiceForm" type="submit"></button>
          
          {/* <button
            type="button"
            onClick={() => handleSetAfterAction('DETAILS')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et afficher les détails</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'DETAILS' && loading} />{' '}
            </span>
          </button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('ADD')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et en ajouter un autre</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'ADD' && loading} />{' '}
            </span>
          </button> */}
        </div>
      </form>
      
    </div>
  );
};

export default NewInvoiceForm;
