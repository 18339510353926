const isDev = false;

const baseUrl = isDev ? "https://gf-apidemo.em-sarl.com" : "https://preprod.gf-api.em-sarl.com"

export const URLS = {
    BASE_API: baseUrl,
    BASE_URL: `${baseUrl}/api/v1/`,
    CLIENT_ID: "rgFz0scCkvH2qAm4iBWwk5m8f6qC9SVM5RHzrKze",
    AUTHS: {
      LOGIN: "auth/login/",
      // LOGIN: "auth/login/",
      REFRESH: "auth/token/refresh/",
      LOGOUT: "auth/logout/",
      REGISTRATION: "auth/signup/",
      MANAGER_REGISTRATION: "accounts/staff/registration/",
      VERIFY_EMAIL: (key) => `user/accounts/confirm-email/${key}/`,
      VERIFY_ACCOUNT: (id) => `/users/verify/${id}/`,
      resetPassword: "accounts/password-reset/",
      resetPasswordAdmin: "accounts/password/admin-reset/",
      me: "users/me/",
      PASSWORD: {
        reset: "auth/password/reset/",
        confirm: "auth/password/reset/confirm/",
      },
      VERIFY: {
        email: "user/accounts/email/verification/",
        resend: "/user/accounts/resend-email/",
      }
    },
    DASHBOARD: {
      list: "dashboard/user/",
      clients: (from, to, agency) => `dashboard/${from}/${to}/customers/${agency}/`,
      productsSells: (from, to, agency) => `dashboard/${from}/${to}/products_sells/${agency === "" ? "all" : agency}/`,
      servicesSells: (from, to, agency) => `dashboard/${from}/${to}/services_sells/${agency === "" ? "all" : agency}/`,
      products: (from, to, agency) => `dashboard/${from}/${to}/products/${agency === "" ? "all" : agency}/`,
      services: (from, to, agency) => `dashboard/${from}/${to}/services/${agency === "" ? "all" : agency}/`,
      caSells: (from, to, agency) => `dashboard/${from}/${to}/ca/${agency === "" ? "all" : agency}/`,
      ALL: {
        clients: (from, to, agency) => `dashboard/${from}/${to}/customers/all/`,
        productsSells: (from, to, agency) => `dashboard/${from}/${to}/products_sells/all/`,
        servicesSells: (from, to, agency) => `dashboard/${from}/${to}/services_sells/all/`,
        products: (from, to, agency) => `dashboard/${from}/${to}/products/all/`,
        services: (from, to, agency) => `dashboard/${from}/${to}/services/all/`,
        caSells: (from, to, agency) => `dashboard/${from}/${to}/ca/all/`,
      }
    },
    NOTIFICATION: {
      list: "notification/",
      my: (extra) => `notification/my/?${extra ? extra : ""}`,
      update: (id) => `notification/${id}/`,
    },
    CUSTOMERS: {
      list: (agency, search, page, extra) => `customer/?search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      indebted_customers: (agency, search, page, extra) => `wallet/indebted_customers/?search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      customers_management: (agency, search, page, extra) => `customer/customers_management/?search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      create: "customer/",
      export: "customer/export_customers/",
      update: (id) => `customer/${id}/`,
      delete: (id) => `customer/${id}/`,
      details: (id) => `customer/${id}/`,
    },
    ATTACHMENTS: {
      list: (agency, search, page, extra) => `attachment/?employee__agency__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      employee: (employee) => `attachment/?employee__id=${employee}`,
      create: "attachment/",
      export: "attachment/export_customers/",
      update: (id) => `attachment/${id}/`,
      delete: (id) => `attachment/${id}/`,
      details: (id) => `attachment/${id}/`,
    },
    PRODUCTS: {
      list: (agency, search, page, type, limit) => `item/?search=${search}&agency__id=${agency}&page=${page ?? 1}${type==="ALL" ? '' : type ? "&type=" + type : "&type=SERVICE"}&limit=${limit ? limit : 60}`,
      inventory: (agency, search, page, type) => `items/inventory/?search=${search}&agency__id=${agency}&page=${page ?? 1}${type==="ALL" ? '' : type ? "&type=" + type : "&type=SERVICE"}`,
      inventoryStock: (agency, search, page, type, extra) => `items/inventory/stocks/?search=${search}&agency__id=${agency}&page=${page ?? 1}${type==="ALL" ? '' : type ? "&type=" + type : "&type=SERVICE"}${extra ? extra : ""}`,
      stock_movement: (agency, search, page, type) => `item/stock_movement/?search=${search}&agency__id=${agency}&page=${page ?? 1}${type==="ALL" ? '' : type ? "&type=" + type : "&type=SERVICE"}`,
      create: "item/",
      update: (id) => `item/${id}/`,
      delete: (id) => `item/${id}/`,
      details: (id) => `item/${id}/`,
      CATEGORIES: {
        create: 'categories/',
        update: (id) => `categories/${id}/`,
        list: (agency, search, page, type) => `categories/?search=${search}&agency__id=${agency}&page=${page ?? 1}&type=${type ?? "PRODUCT"}`,
      }
    },
    APPOINTMENTS: {
      list: (agency, search, page, extra) => `appointment/?search=${search}&agency__id=${agency}&page=${page ?? 1}${extra ? extra : ""}`,
      me: (agency, search, page, extra) => `users/appointment/me/?search=${search}&agency__id=${agency}&page=${page ?? 1}${extra ? extra : ""}`,
      today: (agency, search, page, type) => `appointment/today/?search=${search}&agency__id=${agency}&page=${page ?? 1}&type=${type ?? "SERVICE"}`,
      create: "/appointment/",
      update: (id) => `appointment/${id}/`,
      delete: (id) => `appointment/${id}/`,
      details: (id) => `appointment/${id}/`,
      cancel: (id) => `appointment/${id}/cancel/`,
      complete: (id) => `appointment/${id}/complete/`,
      mark_as_started: (id) => `appointment/${id}/mark_as_started/`,
    },
    AGENCIES: {
      create: `agency/`,
      list: (agency, search, page) => `agency/?agency__id=${agency}&search=${search}&page=${page ?? 1}`,
      lists: (search, page) => `agency/?search=${search}&page=${page ?? 1}`,
      details: (id) => `agency/${id}/`,
      update: (id) => `agency/${id}/`,
      exportCustomers: (id) => `agency/${id}/export_customers/`,
      delete: (id) => `agency/${id}/`,
      payment: (sigle) => `agencies/payment/${sigle}`,
    },
    SALARY: {
      create: `salary/`,
      list: (agency, search, page) => `salary/?employee__agency__id=${agency}&search=${search}&page=${page ?? 1}`,
      details: (id) => `salary/${id}/`,
      update: (id) => `salary/${id}/`,
      delete: (id) => `agency/${id}/`,
    },
    STOCKS: {
      list: (product, search, page) => `stocks/?product__id=${product}&search=${search}&page=${page ?? 1}`,
      list2: (agency, search, page) => `stocks/stocks/?product__agency__id=${agency}&search=${search}&page=${page ?? 1}`,
      lists: (agency, search, page, side, extra) => `stocks/?product__agency__id=${agency}&search=${search}&page=${page ?? 1}&side=${side ?? "IN"}${extra ? extra : ''}`,
      listAll: (agency, search, page, product, extra) => `stocks/?product__agency__id=${agency}&search=${search}&page=${page ?? 1}${product ? '&product__id=' + product : ''}${extra ?? ''}`,
      create: "stocks/",
      update: (id) => `stocks/stocks/${id}/`,
      delete: (id) => `stocks/${id}/`,
      details: (id) => `stocks/${id}/`,
      cancel: (id) => `stocks/${id}/cancel/`,
      PLACES: {
        create: "stocks/places/",
        update: (id) => `stocks/places/${id}/`,
        list: (agency, search, page, extra) => `stocks/places/?agency__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      },
      TRANSFERT: {
        create: "stocks/stock/transfert/",
        update: (id) => `stocks/stock/transfert/${id}/`,
        list: (agency, search, page, extra) => `stocks/stock/transfert/?agencies__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      }
    },
    CART: {
      ITEM: {
        list: (product, search, page) => `cart/item/?product__id=${product}&search=${search}&page=${page ?? 1}`,
        create: "cart/item/",
        update: (id) => `cart/item/${id}/`,
        delete: (id) => `cart/item/${id}/`,
        details: (id) => `cart/item/${id}/`,
      },
      ORDERS: {
        list: (product, search, page) => `cart/order/?product__id=${product}&search=${search}&page=${page ?? 1}`,
        create: "cart/order/",
        update: (id) => `cart/order/${id}/`,
        delete: (id) => `cart/order/${id}/`,
        details: (id) => `cart/order/${id}/`,
        pay: `payment/`,
      }
    },
    PAYMENTS: {
      delete: (id) => `payment/${id}/`,
      invoices: (agency, search, page, extra) => `payment/invoices/?created_by__agency__id=${agency}&search=${search}&page=${page ?? 1}${extra}`,
    },
    USERS: {
      employees: (agency, search, page) => `users/employees/?search=${search}&agency=${agency}&page=${page}`,
      list: (agency, search, page) => `users/u/?agency__id=${agency}&search=${search}&page=${page ?? 1}`,
      update: (id) => `users/u/${id}/`,
      delete: (id) => `users/u/${id}/`,
      details: (id) => `users/u/${id}/`,
    },
    INVOICES: {
      create: "invoice/",
      list: (agency, search, page, extra) => `invoice/?agency__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      export: (agency, search, page, extra) => `invoice/export/?agency__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      customer: (customer, search, page, extra) => `invoice/?order__customer__id=${customer}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      employee: (employee, search, page, extra) => `employees/${employee}/invoices/?search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      byOrder: (orderId) => `invoice/by_order/?order_id=${orderId}`,
      update: (id) => `invoice/${id}/`,
      delete: (id) => `invoice/${id}/`,
      details: (id) => `invoice/${id}/`,
    },
    COUPON: {
      create: "coupon/",
      list: (agency, search, page, extra) => `coupon/?agency__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      update: (id) => `coupon/${id}/`,
      delete: (id) => `coupon/${id}/`,
      details: (id) => `coupon/${id}/`,
    },
    SUPPLIER: {
      create: "supplier/",
      list: (agency, search, page, extra) => `supplier/?agency__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      update: (id) => `supplier/${id}/`,
      delete: (id) => `supplier/${id}/`,
      details: (id) => `supplier/${id}/`,
    }, 
    TRANSFERT: {
      create: "transaction/",
      list: (agency, search, page, extra) => `transaction/?agency__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      update: (id) => `transaction/${id}/`,
      delete: (id) => `transaction/${id}/`,
      details: (id) => `transactions/${id}/`,
    }, 
    WALLET: {
      admin: (agency) => `wallet/admin/?agency__id=${agency}`,
      list: (agency, search, page, extra) => `transaction/?agency__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      trannsactions: (wallet, search, page, extra) => `transaction/?wallet__id=${wallet}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      trannsactionsToday: (wallet, search, page, extra) => `transaction/today/?wallet__id=${wallet}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      allTrannsactions: (search, page, extra) => `transaction/?search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      update: (id) => `transaction/${id}/`, 
      delete: (id) => `transaction/${id}/`,
      details: (id) => `transaction/${id}/`,
      MOBILE: {
        create: "wallet/mobile/",
        list: (agency) => `wallet/mobile/?agency__id=${agency}`,
        update: (agency) => `wallet/mobile/${agency}/`,
      }
    },
    SUPPLIERS: {
      list: (agency, search, page, extra) => `supplier/?agency__id=${agency}&search=${search}&page=${page ?? 1}${extra ? extra : ""}`,
      update: (id) => `supplier/${id}/`,
      delete: (id) => `supplier/${id}/`,
      details: (id) => `supplier/${id}/`,
      create: `supplier/`,
    },
    STATS: {
      EMPLOYEE: {
        list: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/employee/${id}/`,
        all: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/employees/${id}/`,
        agencies: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/employees/agencies/`,
        update: (id) => `transaction/${id}/`,
        delete: (id) => `transaction/${id}/`,
        details: (id) => `transaction/${id}/`,
      },
      REPPORT: {
        daily: (from, to, agency) => `reports/daily/?created_at__gt=${from}&created_at__lt=${to}&agency__id=${agency}`,
        perf: (from, to, agency) => `reports/perf/?created_at__gt=${from}&created_at__lt=${to}&agency__id=${agency}`,
        global: (from, to, agency) => `reports/global/?created_at__gt=${from}&created_at__lt=${to}${agency ? '&agency__id=' + agency : ''}`,
      },
      CA: {
        AGENCY: {
          byDate: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agency/${id}/ca/`,
          today: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agency/${id}/ca/today/`,
          weekly: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agency/${id}/ca/week/`,
          monthly: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agency/${id}/ca/month/`,
          yearly: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agency/${id}/ca/year/`,
          ca: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agency/${id}/ca/day/`,
        },
        AGENCIES: {
          today: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agencies/ca/today/`,
          weekly: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agencies/ca/week/`,
          monthly: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agencies/ca/month/`,
          yearly: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agencies/ca/year/`,
          ca: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agencies/ca/day/`,
        },
        CLOSE_CHECKOUT: {
          ca: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agency/${id}/checkout/close/`,
          close: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agency/${id}/checkout/close/close/`,
          report: (id, from_date, to_date) => `/stats/${from_date}/${to_date}/agency/${id}/checkout/report/`,
        }
      }
    },
    SEARCH: {
      invoices: (agency, search, page, extra) => `/search/invoices/?ordering=-created_at&agency=${agency}${search ? '&search=' + search : ''}&page=${page ?? 1}${extra ? extra : ''}`,
      invoicesByCustomer: (customerId) => `/search/invoices/?customer_id=${customerId}`,
      customers: (agency, search, page, extra) => `/search/customers/?q=l${search ? '&search=' + search : ''}&page=${page ?? 1}${extra ? extra : ''}`,
      transactions: (agency, search, page, extra) => `/search/transactions/?agency=${agency}${search ? '&search=' + search : ''}&page=${page ?? 1}${extra ? extra : ''}`,
      all_transactions: (agency, search, page, extra) => `/search/all_transactions/?agency=${agency}${search ? '&search=' + search : ''}&page=${page ?? 1}${extra ? extra : ''}`,
      walletTransactionsToday: (wallet, search, page, extra) => `/search/transactions/?wallet=${wallet}&page=${page ?? 1}${extra ? extra : ""}`,

    }
}  