import React, { useRef, useState } from 'react';
import useSWR from 'swr';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ManageStockForm from '../../Products/components/ManageStockForm';
import { formatDate, formatNumber } from '../../../components/utils/functions';
import BonDeSortie from '../../Products/components/BonDeSortie';
import LoaderPage from '../../../components/LoaderPage';
import { DEFAULT_PAGE_COUNT } from '../../../components/utils/consts';
import { URLS } from '../../../services/urls';
import { confirmDeletion } from '../../../components/dletion';
import Select from 'react-select';
import { DatePicker } from 'react-responsive-calendar-picker';
import CustomerFromInvoice from './CutomerFromInvoice';
import { useReactToPrint } from 'react-to-print';
import PdfHeader from '../../../components/Pdf/PdfHeader';
import CLoader from '../../../components/Loader';

const StockMovement = ({ type, title, isSelledHistory }) => {
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [toEdit, setToEdit] = useState();
  const [showPdfHeader, setShowPdfHeader] = useState(false);
  const [open, setOpen] = useState();
  const [toEditStock, setToEditStock] = useState();
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [page, setPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [searchProducts, setSearchProducts] = useState('');
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: products,
    isLoading,
    mutate,
  } = useSWR(
    URLS.STOCKS.listAll(
      active_agency?.id,
      search,
      page,
      selectedProduct?.value,
      dates?.checkout
        ? `&limit=10000&ordering=-created_at&created_at__gte=${formatDate(dates.checkin) + 'T00:00'}&created_at__lte=${
            formatDate(dates.checkout) + 'T00:00'
          }`
        : `&limit=10000&ordering=-created_at&created_at=${formatDate(dates.checkin) + 'T00:00'}`
    )
  );
  const { data: prods, isLoading: isLoadingProds } = useSWR(
    URLS.PRODUCTS.list(active_agency?.id, searchProducts, 1, 'PRODUCT')
  );

  const { t } = useTranslation();

  const movementsRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: "",
    content: () => movementsRef?.current,
    documentTitle: 'Mouvements du stock',
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setShowPdfHeader(true);
        setTimeout(resolve, 300); // Give React time to update the state
      });
    },
    onAfterPrint: () => setShowPdfHeader(false)
  });
  

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    setSelected(selectedRows);
  };

  const handleChangePage = (event, page) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };
  const handleShow = (item, obj) => {
    let toShow = {};
    toShow[item] = obj;
    setToEdit(toShow);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="=">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <div className="d-flex h-100">
                  <p className="text-uppercas h4 my-auto">{'Mouvements du stock'}</p>
                </div>
                {/* <h3 className="text-uppercase">Historique des sorties de stock </h3> */}
                {/* <p>Les sorties de stock representent les produits sortis pour utilisation.</p> */}
              </div>
              <div className="col-6">
                <div>
                  {!isSelledHistory && (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light rounded-0 px-3"
                      data-bs-toggle="modal"
                      data-bs-target=".new-stock-modal-out"
                    >
                      {' '}
                      + Sortie de stock
                    </button>
                  )}
                  <div className="row">
                    <div className="col d-none ">
                      <div className="form search-form">
                        <i className="fa fa-search"></i>
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          class="form-control form-input py-2"
                          placeholder="Rechercher (Ref produit, Designation, etc ...)"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div>
                        <Select
                          className="basic-single-product"
                          classNamePrefix="select"
                          isLoading={isLoading}
                          value={selectedProduct}
                          isClearable={true}
                          isSearchable={true}
                          onChange={(newValue) => setSelectedProduct(newValue)}
                          onInputChange={(value) => setSearchProducts(value)}
                          
                          name="customer"
                          options={[{label: "Tous les produits", value: "ALL"}].concat(prods?.results?.map((product) => {
                            return { value: product.id, label: `${String(product?.title).toUpperCase()}` };
                          }))}
                        />
                      </div>
                    </div>
                    <div className="col-auto">
                      <div>
                        <div className=" position-relative" style={{ zIndex: '1' }}>
                          <DatePicker
                            dates={dates}
                            setDates={setDates}
                            open={open}
                            setOpen={setOpen}
                            normal={true}
                          >
                            <button
                              className="btn btn-primary rounded-"
                              onClick={() => setOpen(!open)}
                            >
                              Date du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                              {dates.checkout && 'au'}{' '}
                              {dates.checkout && dates.checkout.toLocaleDateString()}
                            </button>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}

            {/* Manage stock */}

            {!isSelledHistory && (
              <div
                className="modal fade new-stock-modal-out"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="myLargeModalLabel">
                        {'Nouvelle sortie de stock'}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <ManageStockForm
                        handleClose={handleClose}
                        onSuccess={handleSuccess}
                        toEdit={toEditStock}
                        side={'OUT'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className="row">
                <div>
                  <div>
                    <div>
                      {/* <Select
                    className="basic-single-product"
                    classNamePrefix="select"
                    placeholder={"Choisir un produit"}
                    isLoading={isLoadingProds}
                    value={selectedProduct}
                    // isClearable={true}
                    // isSearchable={true}
                    onChange={(newValue) => setSelectedProduct(newValue)}
                    onInputChange={(value) => setSearchProducts(value)}
                    
                    name="customer"
                    options={
                        prods ?[ {label: "Tout voir", value: ""}, ...prods?.results?.map((product) => {
                        return { value: product.id, label: `${product?.title}` };
                    })]: []}
                    /> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  {/* <div className="d-flex justify-content-end">
                      <ExportExcel
                        label={'Exporter en Excel'}
                        sheetName={`Customers page: ${page}`}
                        columns={type === "SERVICES" ? exportServices : exportColumns}
                        dataToExport={(products?.results ?? []).map((customer) => {
                          return {
                            ...customer,
                            marge: `${customer.price - customer.buy_price}`,
                            created_by: `${customer?.created_by?.first_name} ${customer?.created_by?.last_name}`,
                          };
                        })}
                      />
                    </div> */}
                </div>
              </div>
              <div></div>
              <hr className="mb-0" />
              <div className="table-responsive" ref={movementsRef}>
                {
                  showPdfHeader && <PdfHeader documentTitle={"Mouvements du stock"} />
                } 
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="text-uppercas">Date </th>
                      <th className="text-uppercas">Référence</th>
                      <th className="text-uppercas">Tiers</th>
                      {/* <th className="text-uppercas">Désignation</th> */}
                      {type !== 'SERVICES' ? (
                        <>
                          {/* <th className="text-uppercas">{isSelledHistory ? "Qtés vendues" : "Quantité"}</th>
                          <th className="text-uppercas">{ isSelledHistory ? "Coût d'achat" : "Prix d'achat"}</th>
                          <th className="text-uppercas">{ isSelledHistory ? "Montant Total" : "Prix de vente"}</th>
                          <th className="text-uppercas">Marge</th> */}
                          <th>Entrée</th>
                          <th>Sortie</th>
                          <th>Stock</th>
                          {/* <th className="text-uppercas">Emplacement</th> */}
                        </>
                      ) : (
                        <>
                          <th className="text-uppercas">Prix</th>
                        </>
                      )}
                      {/* <th className="text-uppercas">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>
                        <span className="text-uppercase fw-bold">Stock initial</span>
                      </td>
                      <td colSpan={3}></td>
                      <td>
                        <span className="badge badge-soft-success">
                          {
                            products?.results?.length > 0 ? (parseFloat(products?.results[0]?.quantity) + parseFloat(products?.results[0]?.new_quantity)) : 0
                          }
                        </span>
                      </td>
                    </tr>
                    {(products?.results ?? [])?.map((item) => (
                      <tr key={'itm-' + item.id}>
                        <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                        {/* <td>{moment(item.date).format('DD/MM/YYYY')}</td> */}
                        <td>{item.document_number !== "" ? item.document_number : item.designation}</td>
                        <td>{item?.supplier !== "" ? item.supplier : <CustomerFromInvoice reference={item.designation} />}</td>
                        <td>
                          <span className="badge badge-soft-success">
                            {item?.side === 'IN' ? item.quantity : ''}
                          </span>
                        </td>
                        <td>
                          <span className="badge badge-soft-success">
                            {item?.side === 'OUT' ? item.quantity : ''}
                          </span>
                        </td>
                        <td>
                          <span className="badge badge-soft-success">{item.new_quantity}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div>
                  <div
                    className="modal fade bon-sortie-modal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeInvoicdseModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="myLargeInvoicdseModalLabel">
                            {'Facture ' + selectedInvoice?.reference}
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <BonDeSortie invoice={selectedInvoice} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>{isLoading && <LoaderPage />}</div>
              </div>
              <div className="d-flex mt-4">
                <div className="ms-auto">
                  <button className="btn btn-primary position-relative" disabled={showPdfHeader} onClick={handlePrint}>
                    Imprimer
                    <span className='position-absolute top-50 start-50 translate-middle'>{showPdfHeader ? <CLoader show={true} /> : <></>}</span>
                  </button>
                </div>
              </div>
              <div className="d-flex d-none">
                <div className="ms-auto">
                  <div>
                    {products?.count > 0 && (
                      <div className="paginations pt-3">
                        <ul className="pagination pagination-rounded">
                          <li>
                            <Link
                              tabIndex={-1}
                              onClick={(event) => handleChangePage(event, page - 1)}
                              className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                          {new Array(Math.ceil(products.count / DEFAULT_PAGE_COUNT))
                            .fill(3)
                            .map((pag, index) => (
                              <li
                                key={'page' + index}
                                className={`page-item ${page === index + 1 ? 'active' : ''}`}
                                aria-current="page"
                              >
                                <Link
                                  className={`pager-number ${
                                    page * DEFAULT_PAGE_COUNT === Math.ceil(products.count)
                                      ? 'disabled'
                                      : ''
                                  } ${page === index + 1 ? 'active' : ''}`}
                                  to="#"
                                  tabIndex={-1}
                                  onClick={(event) => handleChangePage(event, index + 1)}
                                >
                                  <span className="page-link">{index + 1}</span>
                                </Link>
                              </li>
                            ))}
                          <li>
                            <Link
                              tabIndex={-1}
                              className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockMovement;
