import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ExportExcel from '../../components/download/DownloadExcel';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import NewInvoiceForm from '../Invoices/components/NewInvoiceForm';
import NewAppointmentForm from '../Appointment/components/NewAppointmentForm';
import DepositForm from '../Finances/Checkouts/DepositForm';
import InvoiceTicket from '../Invoices/components/InnvoiceTicket';
import PageTitle from '../../components/PageTitle';
import { formatNumber, formateDate } from '../../components/utils/functions';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import LoaderPage from '../../components/LoaderPage';
import PaginationComponent from '../../components/Pagination';
import EmptyDataToDisplay from '../../components/EmptyData';

const IndebtedCustomers = () => {
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [search, setSearch] = useState('');
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState();

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: customers,
    isLoading,
    mutate,
  } = useSWR(URLS.CUSTOMERS.indebted_customers(active_agency?.id, search, page, ''));

  const { t } = useTranslation();

  const handleDisplayInvoice = (invoice) => {
    setSelectedInvoice(invoice);
  };

  const handleChangePage = (page) => {
    // event?.preventDefault();
    if (page) {
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccessAppointment = () => {
    window.$('.new-appointment-modal')?.modal('hide');
  };

  const handleCloseAppointment = () => {
    // window.$(".new-invoice-modal .btn-close")?.click();
    // var detailsModal = new window.bootstrap.Modal(document.getElementById('customerDetailsModal'), {
    // })
    // detailsModal?.show();
  };

  const handleCloseDetails = () => {
    window?.$(".modal-backdrop.fade").hide()
  }

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.CUSTOMERS.delete(obj.id), t, mutate);
  };

  const handleShowCustomerDetails = (customer) => {
    if (customer) {
      setSelectedCustomer(customer);
    }
    var detailsModal = new window.bootstrap.Modal(
      document.getElementById('customerDetailsModal'),
      {}
    );
    detailsModal?.show();
  };

  const columns = useMemo(() => [
    {
      name: 'Code client',
      selector: (row) => `${row.reference}`,
      sortable: true,
      val: 'reference',
    },
    {
      name: 'Nom complet',
      selector: (row) => `${row.first_name ?? ''} ${row.last_name ?? ''}`,
      sortable: true,
      val: 'first_name',
    },
    {
      name: 'Contact',
      selector: (row) => `${row.phone_number}`,
      sortable: true,
      val: 'phone_number',
    },
    {
      name: 'Adresse Mail',
      selector: (row) => `${row.email}`,
      sortable: true,
      val: 'email',
    },
    {
      name: 'Montant de la Dette',
      selector: (row) => (
        <span className={`${row?.wallet?.amount > 0 ? 'text-success' : 'text-danger'}`}>
          {row?.wallet?.amount}
        </span>
      ),
      sortable: true,
      val: 'email',
    },
    {
      name: 'Date de Naissance',
      selector: (row) => row.birth_date,
      sortable: true,
      val: 'birth_date',
    },
    {
      name: "Date d'Ajout",
      selector: (row) => moment(row.created_at).format('lll'),
      sortable: true,
      val: 'created_at',
    },
  ]);

  useEffect(() => {
    window?.$('.new-invoice-modal').on('hidden.bs.modal', function (e) {
      handleShowCustomerDetails();
    });
    window?.$('.new-client-modal').on('hidden.bs.modal', function (e) {
      handleShowCustomerDetails();
    });
    window?.$('.customer-details-modal').on('hidden.bs.modal', function (e) {
      // window?.$(".modal-backdrop.fade").hide()
    });
    window?.$('.customer-details-modal').on('hidden.bs.modal', function (e) {
      // window?.$(".modal-backdrop.fade").hide()
    });

    window?.$('.details-invoice-modal').on('hidden.bs.modal', function () {
      window?.$('.modal-backdrop.fade').hide();
    });
  }, []);

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Facturations'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Facturations
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-md-6">
        <div className="d-flex h-100">
          <div className="ms-auto my-auto">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              data-bs-toggle="modal"
              data-bs-target=".new-client-modal"
            >
              {' '}
              + Ajouter un client
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="card mb-0">
          <div className="card-header">
            <div className="row">
              <div className="col-md-7">
                <div>
                  <div className="c-table-card-title">Facturations</div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row">
                  <div className="col">
                    <div>
                      <div className="form search-form">
                        <i className="fa fa-search"></i>
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          class="form-control form-input py-2"
                          placeholder="Rechercher"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div>
                      <ExportExcel
                        label={'Exporter'}
                        link={URLS.AGENCIES.exportCustomers(active_agency?.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="text-uppercase"> </h3>
              </div>
              <div>
                
              </div>
            </div>
            <div
              className="modal fade customer-details-modal"
              tabIndex={-1}
              // role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
              id="customerDetailsModal"
            >
              
            </div>

            <div>
              
              {
              isLoading ? <LoaderPage />
              : <div class="table-responsive">
                <table class="table table-hover c-table-hover">
                  <thead>
                    <tr>
                      {columns?.map((item, i) => (
                        <th scope="col" key={'itm_' + i}>
                          {item?.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {(customers?.results ?? [])?.map((row) => (
                      <tr class="" key={'ki_' + row?.id}>
                        <td>{row?.customer?.reference}</td>
                        <td>{`${row?.customer?.first_name ?? ''} ${row?.customer?.last_name ?? ''}`}</td>
                        <td>{row?.customer?.phone_number}</td>
                        <td>{row?.customer?.email !== 'null' ? row?.customer?.email : '-'}</td>
                        <td>
                          <span
                            className={`${
                              row?.customer?.wallet?.amount > 0 ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {formatNumber(-(row?.amount))} XAF
                          </span>
                        </td>
                        <td>{row?.customer?.birth_date ? moment(row?.customer?.birth_date).format('DD/MM/YYYY') : '-'}</td>
                        <td>{formateDate(row?.customer?.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              }

                <div>
                {
                    customers?.results?.length === 0 && <EmptyDataToDisplay />
                }
                </div>
            </div>
          </div>
        </div>
      </div>
      {/* Table pagination */}
      <div className="d-flex">
        <div className="ms-auto">

          <div className="pt-3">
          {
            customers?.count > 0 && (
              <PaginationComponent currentPage={page} handlePageChange={handleChangePage} totalPages={Math.ceil(customers.count / DEFAULT_PAGE_COUNT) ?? 0} />
            )
          }
          </div>

        </div>
      </div>
      {/* Creations form */}

      <div>
        <div
          className="modal fade new-invoice-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeModalLabel2"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeModalLabel2">
                  {`Nouvelle Facture Pour le client: ${selectedCustomer?.first_name} ${selectedCustomer?.last_name}`}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <NewInvoiceForm
                  handleClose={handleClose}
                  onSuccess={handleSuccess}
                  toEdit={toEdit}
                  customerId2={selectedCustomer?.id}
                  handleDisplayInvoice={handleDisplayInvoice}
                  productType={"SERVICE"}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade new-appointment-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeModalLabel">
                  {'Nouvelle Prise de RDV Pour le Client: ' +
                    selectedCustomer?.first_name +
                    ' ' +
                    selectedCustomer?.last_name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target=".customer-details-modal"
                ></button>
              </div>
              <div className="modal-body">
                <NewAppointmentForm
                  handleClose={handleCloseAppointment}
                  onSuccess={handleSuccessAppointment}
                  toEdit={toEdit}
                  customerId2={selectedCustomer?.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Appro customer account */}
      <div
        // {!hideCreationForms && <div
        className="modal fade new-deposit-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="myLargeModalLabel2">
                {'Approvisionnement du compte client'}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <DepositForm
                handleClose={handleClose}
                onSuccess={handleSuccess}
                wallet={selectedCustomer?.wallet}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Invoice modal */}
      <div>
        <div
          className="modal fade details-invoice-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeInvoiceModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeInvoiceModalLabel">
                  {'Facture ' + selectedInvoice?.reference}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <InvoiceTicket invoice={selectedInvoice} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndebtedCustomers;
