import useSWR from 'swr';
import { URLS } from '../../../services/urls';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NewInvoiceForm from '../../Invoices/components/NewInvoiceForm';
import { DEFAULT_PAGE_COUNT } from '../../../components/utils/consts';
import InvoiceTicket from '../../Invoices/components/InnvoiceTicket';
import { sumArray } from '../../../components/utils/functions';

const formatDate = (date) => {
    if (date) {
      // return String(date.toLocaleDateString()).split('/').reverse().join('-') + "T00:00";
      return moment(date).format('YYYY-MM-DD') + "T00:00";
    } else {
      return '9999-01-01' + "T00:00";
    }
};

const EmployeeInvoices = ({ employee, dates, employeeData }) => {
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);
  const [invoice, setInvoice] = useState();
  
  const { active_agency } = useSelector((state) => state.agencies);
  const {
    data: invoices,
    isLoading,
    mutate,
  } = useSWR(URLS.INVOICES.employee(employee, '', page, `&limit=10&created_at__date__gte=${formatDate(dates.checkin)}&created_at__lte=${formatDate(dates.checkout)}`));

  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();

    setToEdit();
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
    window.$('.new-recent-modal')?.modal('show');
  };

  const handleShowInvoice = (obj) => {
    setInvoice(obj)
    window.$('.details-invoice-modal')?.modal('show');
  };

  return (
    <div className="card p-3">
      <div className="card-body">
        <div className="d-flex">
          <h4>Réalisations</h4>
          <div className=" d-flex ms-auto justify-content-center">
            <button
                className="d-flex btn btn-primary rounded-0 px-4"
            >
                Imprimer
            </button>
            <div
              className="modal fade new-recent-invoice-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myLargeModalLabelLatestInvoices"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="myLargeModalLabelLatestInvoices">
                      {toEdit ? `Facture ${toEdit?.reference}` : 'Nouvelle facture'}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <NewInvoiceForm
                      handleClose={handleClose}
                      onSuccess={handleSuccess}
                      toEdit={toEdit}
                      productType="ALL"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-2">
          <div className="table-responsive">
            <table className="table align-middle table-hover table-nowrap mb-0">
              <thead className='bg-light text-uppercase'>
                <tr>
                  <th style={{ width: '20px' }}>
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="customCheck1" />
                      <label className="form-check-label" for="customCheck1">
                        &nbsp;
                      </label>
                    </div>
                  </th>

                  <th>Date de création</th>
                  <th>Code Facture</th>
                  <th>Montant</th>
                  {/* <th>Mode de règlement</th> */}
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {invoices?.results?.map((transfert) => (
                  <tr key={'tr-' + transfert.id} className='cursor-pointer' onClick={() => handleShowInvoice(transfert)}>
                    <td>
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="customCheck2" />
                        <label className="form-check-label" for="customCheck2">
                          &nbsp;
                        </label>
                      </div>
                    </td>
                    <td>
                      <>
                        {' '}
                        {moment(transfert?.created_at).format('DD/MM/YYYY')}{' '}
                        <span className="ms-3">
                          {moment(transfert?.created_at).format('HH:MM')}
                        </span>
                      </>
                    </td>
                    <td>
                      <Link to={`#`} className="text-body">
                        {transfert.reference}
                      </Link>{' '}
                    </td>
                    <td>
                      {
                        <span className="badge rounded badge-soft-success font-size-12">
                          {sumArray(
                            transfert?.order?.items?.map((itm) => itm?.executor?.id === employee ? itm.final_price : 0)
                          ) + ' FCFA' ?? '0'}
                        </span>
                      }
                    </td>
                    {/* <td>
                      <p className="mb-0">
                        {transfert?.payments?.map(
                          (item, index) =>
                            item.payment_method +
                            (transfert?.payments?.length > index + 1 ? ', ' : '')
                        )}
                      </p>
                    </td> */}
                    <td>
                      <span className="badge rounded badge-soft-success font-size-12">
                      {sumArray(
                            transfert?.order?.items?.map((itm) => itm?.executor?.id === employee ? itm.final_price : 0)
                          ) * (employeeData?.comission_percentage ?? 10) * 0.01 + ' FCFA' ?? '0'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            {invoices?.count > 0 && (
                      <div className="paginations pt-3">
                        <ul className="pagination pagination-rounded">
                          <li>
                            <Link
                              tabIndex={-1}
                              onClick={(event) => handleChangePage(event, page - 1)}
                              className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                          {new Array(Math.ceil(invoices.count / DEFAULT_PAGE_COUNT))
                            .fill(3)
                            .map((pag, index) => (
                              <li
                                key={'page' + index}
                                className={`page-item ${page === index + 1 ? 'active' : ''}`}
                                aria-current="page"
                              >
                                <Link
                                  className={`pager-number ${
                                    page * DEFAULT_PAGE_COUNT === Math.ceil(invoices.count)
                                      ? 'disabled'
                                      : ''
                                  } ${page === index + 1 ? 'active' : ''}`}
                                  to="#"
                                  tabIndex={-1}
                                  onClick={(event) => handleChangePage(event, index + 1)}
                                >
                                  <span className="page-link">{index + 1}</span>
                                </Link>
                              </li>
                            ))}
                          <li>
                            <Link
                              tabIndex={-1}
                              className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                        </ul>
                      </div>
                    )}
          </div>
        </div>
      </div>
      <div className="">
      <div>
        <div
          className="modal fade details-invoice-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeInvoiceModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeInvoiceModalLabel">
                  {'Facture ' + invoice?.reference}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <InvoiceTicket invoice={invoice}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default EmployeeInvoices;
