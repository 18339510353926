import { Link, useParams } from 'react-router-dom';
import { URLS } from '../../services/urls';
import useSWR from 'swr';
import LoaderPage from '../../components/LoaderPage';
import moment from 'moment';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NewInvoiceForm from './components/NewInvoiceForm';
import { handlePrintInvoice } from '../../components/utils/functions';
import InvoiceTicket from './components/InnvoiceTicket';

const InvoiceDetails = () => {
  const [toEdit, setToEdit] = useState();
  const { invoiceId } = useParams();
  const { data: invoice, isLoading, mutate } = useSWR(URLS.INVOICES.details(invoiceId));


  const handleClose = () => {
    window.$('.btn-close')?.click();
  };


  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleEdit = () => {
    setToEdit(invoice);
    window.$('.new-invoice-modal2')?.modal('show');
  };

  const handlePrintCoupon = () => {
    handlePrintInvoice(invoice?.order?.id);
    window.$('.details-invoice-modal')?.modal('show');

  }


  const { active_agency } = useSelector((state) => state.agencies);

  useEffect(() => {
    window?.$(".modal-backdrop.fade").hide()
  }, []);
  return (
    <div className="row py-5">
      <div>
      <div
                  className="modal fade new-product-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel">
                          {toEdit ? `Facture ${toEdit?.reference} ` : 'Nouvelle facture'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewInvoiceForm
                          // type={'PRODUCT'}
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={invoice}
                        />
                      </div>
                    </div>
                  </div>
                </div>
      </div>
      {/* <div className="col-12">
        <nav aria-label="breadcrumb" className="main-breadcrumb card">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/invoices/list">Liste des factures</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Details de la facture
            </li>
          </ol>
        </nav>
      </div> */}
      <div className="col-lg-12">
        <div className="card">
          <div className="card-title">
            <div className="button-items px-5 pt-3">
              {/* <button className='rounded-0 btn btn-primary'>Imprimer</button> */}
              {invoice?.status === "PENDING" && <button
              className='rounded-0 btn btn-info'
                                  title="Modifier"
                                  data-bs-toggle="modal"
                                  data-bs-target=".new-product-modal"
              >Modifier</button>}
            </div>
          </div>
          <div className="card-body">
            <div className="invoice-title">
              {/* <h4 className="float-end font-size-15">
                Invoice #{invoice?.reference} <span className={`badge bg-${invoice?.status === "PENDING" ? 'warning' : invoice?.status === "COMPLETED" ? 'success' : 'danger'} font-size-12 ms-2`}>{invoice?.status === "PENDING" ? 'En attente' : invoice?.status === "COMPLETED" ? 'Payée' : 'Annulée'}</span>
              </h4> */}
              <div className="mb-4">
                <img src="/assets/images/logo-dark.webp" alt="logo" height="70" />
                <p className="mb-0">INSTITUT DE BIEN - ETRE - COIFFURES - SOINS CORPORELS - MASSAGE - ESPACE DETENTE</p>
                  <strong>RCCM</strong>: RC/DLN/2021/B/652 -- <strong>NIU</strong>: M022118505410B 
                 
              </div>
              <div className="text-muted">
                <p className="mb-1">{invoice?.agency?.address}</p>
                <p className="mb-1">
                  <i className="mdi mdi-email-outline me-1"></i> {invoice?.agency?.email}
                </p>
                <p className="mb-1">
                  <i className="mdi mdi-phone-outline me-1"></i> {invoice?.agency?.phone_number}
                </p>
                <p>
                  <i className="mdi mdi-web me-1"></i> http://goodfeeling-cm.com
                </p>
              </div>
            </div>

            <hr className="my-4" />

            <div className="row">
              <div className="col-sm-6">
                <div className="text-muted">
                  <h5 className="font-size-16 mb-3">Facture de:</h5>
                  <h5 className="font-size-15 mb-1">{invoice?.order?.customer?.first_name} {invoice?.order?.customer?.last_name}</h5>
                  <p className="mb-0">{invoice?.order?.customer?.address ?? ""}</p>
                  <p className="mb-0">{invoice?.order?.customer?.email}</p>
                  <p className="mb-0">{invoice?.order?.customer?.phone_number}</p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="text-muted text-sm-end">
                  <div>
                    <h5 className="font-size-15 mb-1">Facture No:</h5>
                    <p className="mb-0">#{invoice?.reference}</p>
                  </div>
                  <div className="mt-3">
                    <h5 className="font-size-15 mb-1">Date:</h5>
                    <p className="mb-0">{moment(invoice?.created_at).format("DD-MM-YYYY HH:MM")}</p>
                  </div>
                  <div className="mt-3">
                    <h5 className="font-size-15 mb-1">Commande No:</h5>
                    <p className="mb-0">#{invoice?.order?.ref_code}</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h5 className="font-size-15 mb-3">Sommaire de la commande</h5>

              <div className="table-responsive">
                <table className="table align-middle table-nowrap table-centered mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th style={{width: "70px"}}>No.</th>
                      <th>Désignation</th>
                      <th>Quantité</th>
                      <th>Prix</th>
                      <th className="text-end" style={{width: "120px"}}>
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        invoice?.order?.items?.map((item, index) => (
                        <tr key={item.id}>
                      <th scope="row">{ "0" + (index + 1)}</th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14 mb-1">{item.item?.title}</h5>
                          <p className="text-muted mb-0 text-uppercase">{item.item?.cat?.name}</p>
                        </div>
                      </td>
                      <td>{item.quantity}</td>
                      <td>{Number(Math.round(item.price_ht)).toLocaleString()} FCFA</td>
                      <td className="text-end">{Number(Math.round(item.sub_total_ht)).toLocaleString()} FCFA</td>
                    </tr>
                        ))
                    }
                    
                    <tr>
                      <th scope="row" colspan="4" className="text-end">
                        Sous total :
                      </th>
                      <td className="text-end">{Number(Math.round(invoice?.sub_total)).toLocaleString() ?? 0} FCFA</td>
                    </tr>
                    <tr>
                      <th scope="row" colspan="4" className="border-0 text-end">
                        Remise :
                      </th>
                      <td className="border-0 text-end"> {Number(Math.round(invoice?.discount_amount ?? 0)).toLocaleString()} FCFA</td>
                    </tr>
                    <tr>
                      <th scope="row" colspan="4" className="border-0 text-end">
                        Net HT : 
                      </th>
                      <td className="border-0 text-end"> {Number(Math.round(invoice?.price_exclude_tax)).toLocaleString() ?? 0} FCFA</td>
                    </tr>
                    {/* <tr>
                      <th scope="row" colspan="4" className="border-0 text-end">
                        Shipping Charge :
                      </th>
                      <td className="border-0 text-end">$20.00</td>
                    </tr> */}
                    <tr>
                      <th scope="row" colspan="4" className="border-0 text-end">
                        TVA (19.25%):
                      </th>
                      <td className="border-0 text-end">{Number(Math.round(invoice?.tax_value)).toLocaleString()}</td>
                    </tr>
                    <tr>
                      <th scope="row" colspan="4" className="border-0 text-end">
                        TTC:
                      </th>
                      <td className="border-0 text-end">
                        <h4 className="m-0 fw-semibold">{Number(invoice?.amount).toLocaleString()} FCFA</h4>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" colspan="4" className="border-0 text-end">
                        Payé par:
                      </th>
                      <td className="border-0 text-end">
                        <h4 className="m-0 fw-semibold">
                        {invoice?.payments?.map(
                                (pm, idx) =>
                                  String(pm.payment_method).replace('_', ' ') +
                                  (idx + 1 === invoice?.payments?.length ? '' : ', ')
                              )}
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-print-none mt-4">
                <div className="float-end">
                  <Link to="#" className="btn btn-success me-1" onClick={handlePrintCoupon}>
                    {/* <i className="fa fa-print"></i> */}
                    Imprimer le coupon
                  </Link>
                  <a href="javascript:window.print()" className="btn btn-success me-1">
                    {/* <i className="fa fa-print"></i> */}
                    Imprimer A4 
                  </a>
                  {invoice?.status === "PENDING" && <Link to="#" className="btn btn-primary w-md" 
                  data-bs-toggle="modal"
                  data-bs-target=".new-invoice-modal2" onClick={handleEdit}>
                  Payer la facture
                  </Link>}
                  <div
                  className="modal fade new-invoice-modal2"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel">
                          {toEdit
                            ? `Facture ${toEdit?.reference}`
                            : 'Nouvelle facture'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewInvoiceForm
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="modal fade details-invoice-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeInvoiceModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeInvoiceModalLabel">
                  {'Facture ' + invoice?.reference}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <InvoiceTicket invoice={invoice}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
