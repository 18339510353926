import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { formatDate, formatNumber, handleFocus, sumArray } from '../../components/utils/functions';
import LoaderPage from '../../components/LoaderPage';
import { API } from '../../services/axios';
import OutStockModalLists from './OutboxModal';
import { DatePicker } from 'react-responsive-datepicker';
import 'react-responsive-datepicker/dist/index.css'
import PdfHeader from '../../components/Pdf/PdfHeader';
import CLoader from '../../components/Loader';



const Inventory = ({ type }) => {
  const [showStockMvt, setShowStockMvt] = useState(
    {
      "code": "",
      "side": "",
    }
  );
  const [controlMode, setControlMode] = useState(false);
  const [search, setSearch] = useState('');
  const [toEdit, setToEdit] = useState();
  const [prods, setProds] = useState([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(moment().format('DD/MM/YYYY'));
  const [showPdfHeader, setShowPdfHeader] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { active_agency } = useSelector((state) => state.agencies);

  const { 
    data: products,
    isLoading,
    mutate,
  } = useSWR(
    URLS.PRODUCTS.inventory(
      active_agency?.id,
      search,
      page,
      type === 'SERVICES' ? 'SERVICE' : 'PRODUCT'
    )
  );
  const {
    data: inventoryStocks,
    isLoading: loadingStocks,
    mutate: mutateStock
  } = useSWR(
    URLS.PRODUCTS.inventoryStock(
      active_agency?.id,
      search,
      page,
      type === 'SERVICES' ? 'SERVICE' : 'PRODUCT',
      `&created_at__gte=${formatDate(dates.checkin) + "T00:00"}&created_at__lte=${formatDate(dates.checkout) + "T00:00"}`
    )
  );

  const handleChangeDate = (event) => {
    setDate(event.target.value);
    // if(value?.length === 2) {
    //   console.log("value");
    //   setDates(
    //     {
    //       checkin: value[0],
    //       checkout: value[1],
    //     }
    //   )
    // }
  }
  

  const { t } = useTranslation();

  const handleSuccess = () => {
    mutate();
    mutateStock();
  };

  const handlePageChange = ({ page }) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const inventoryRef = useRef();

  const handleChangeStock = (stockId, value) => {
    API.patch(URLS.STOCKS.update(stockId), {real_quantity: value}).then((resp) => {
      mutateStock();
    });
  }

  const handlePrint = useReactToPrint({
    content: () => inventoryRef?.current,
    documentTitle: "Saisie inventaire du " + moment().format("DD/MM/YYYY"),
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setShowPdfHeader(true);
        setTimeout(resolve, 300); // Give React time to update the state
      });
    },
    onAfterPrint: () => setShowPdfHeader(false)
  });
  

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.PRODUCTS.delete(obj.id), t, mutate);
  };

  const dateInputRef = useRef(null);

  const handleLabelClick = () => {
    dateInputRef.current?.showPicker(); // Native method to show date picker
  };

  useEffect(() => {
    if(products){
      console.log(products)
      products.forEach(product => {
        console.log("product ===============>, ", product);
        if(product?.items?.length > 0){
          setProds((prev) => _.uniqWith([...product?.items], _.isEqual))
        }
      });
    }
  }, [products])

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-md-6">
            <div className='d-flex h-100'>
              <p className="text-uppercas h4 my-auto">Saisie inventaire</p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <div className="row">
                <div className="col">
                  <div className="py-2">
                    
                  </div>
                </div>
                <div className="col-auto">
                <div className='d-flex h-100'>
                  <div className="my-auto px-4">
                    {<button onClick={handlePrint} type="button" className="px-3 rounded-0 btn btn-primary waves-effect waves-light">
                      {' '}
                      Imprimer
                      <span className='position-absolute top-50 start-50 translate-middle'>{showPdfHeader ? <CLoader show={true} /> : <></>}</span>
                    </button>}
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div>
        {/* <nav aria-label="breadcrumb" className="main-breadcrumb border-bottom border-1">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item active" aria-current="page">
              Saisie inventaire
            </li>
          </ol>
        </nav> */}
          
          
        </div>
        <div className="">
          <div className="py-3">
            <div className="d-flex justify-content-between flex-column">
              {/* <div>
                <h3 className="text-uppercase">Saisie inventaire </h3>
              </div> */}
              <div className='d-flex'>
                
                <div className="ms-auto">
                {/* <div>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light px-3 rounded-0"
                    data-bs-toggle="modal"
                    data-bs-target=".new-product-modal"
                  >
                    {' '}
                    + Ajouter un {type === "SERVICES" ? "service" : "produit"}
                  </button>
                  <div
                    className="modal fade new-product-modal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="myLargeModalLabel">
                            {
                              toEdit ? "Modifitation du " + type === "SERVICES" ? "service" : "produit" + (toEdit.title ?? "") : "Nouveau " + (type === "SERVICES" ? "service" : "produit")
                            }
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <NewProductForm type={type === "SERVICES" ? "SERVICE" : "PRODUCT"} handleClose={handleClose} onSuccess={handleSuccess} toEdit={toEdit} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
            

            {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}

            {/* Manage stock */}

            <div
              className="modal fade displ-stock-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="myLargeModalLabel">
                      {
                        showStockMvt?.side === "IN" ? "Historique des Entrées" : "Historique des Sorties"
                      }
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div>
                      { showStockMvt.code && <OutStockModalLists showStockMvt={showStockMvt} type={"PRODUCTS"} title={"Déstockage"}/> }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loadingStocks && <div className="py-3">
              <LoaderPage />
            </div>
            }
            <DatePicker
              dates={dates}
              setDates={setDates}
              isOpen={open}
              onClose={() => setOpen(false)}
              normal={false}
              detail={false}
              defaultValue={new Date()}
              onChange={(value) => setDates({...dates, checkin: value})}
              colorScheme="#71b444"
              />

            <div ref={inventoryRef} className='p-4'>
              {
                showPdfHeader && <PdfHeader documentTitle={"Inventaire"} />
              }
            <div className="py-4 ">
              <div className="row">
                <div className="col-md-4 col-6">
                    <div>
                      <table className='table text-uppercase'>
                        <tbody>
                          <tr>
                            <td className='border border-end bg-light'>Date</td>
                            {/* <td className='border border-end'>{moment().format('DD/MM/YYYY')} <span className='ms-2'>{moment().format('hh:mm')}</span> </td> */}
                            <td className='border border-end'>
                              <input type="date" id="inventory-date" onChange={handleChangeDate} ref={dateInputRef} value={moment(date).format("yyyy-MM-DD")} className="border-0" />
                              {/* <input type="date" id="inventory-date" ref={dateInputRef} /> */}
                            </td>
                            <td>
                            <div className="position-relative" style={{ zIndex: '1' }}>
                          
                            {/* <button
                              className="btn btn-primary rounded-0"
                              onClick={() => setOpen(!open)}
                            >
                              Date: {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                            </button> */}
                            <label onClick={handleLabelClick} htmlFor="inventory-date" className="btn btn-primary rounded-0 px-3">
                              <i className="mdi mdi-calendar fs-4"></i>
                            </label>
                        </div>
                        </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                <div className="col-md-8 col-6">
                    <div>
                      <table className='table'>
                        <tbody>
                          <tr className="bg-light text-uppercase">
                            <td>Quantité disponible</td>
                            <td>Montant Global</td>
                          </tr>
                          <tr>
                            <td className='border border-end'>
                              {
                                formatNumber(sumArray(
                                  prods?.map(prod => prod.quantity)
                                )) 
                              }
                            </td>
                            <td className='border border-end'>{
                              formatNumber(sumArray(
                                prods?.map(prod => prod.buy_price * prod.quantity)
                              )) 
                            } </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
              </div>
            </div>
              <div className="row py-0">
                  <div className="col-12">
                    <div className="d-flex">
                      <div className="ms-auto">
                        <button onClick={() => setControlMode(!controlMode)} className="btn btn-primary text-uppercase rounded-0">Contrôle</button>
                      </div>
                    </div>
                  </div>
              </div>
              {
                inventoryStocks?.map((stock) => (
                  <div key={"inventory-" + stock.id} className='mb-3'>
                    <p className="fs-4 text-uppercase">Emplacement: {stock.name}</p>
                    {
                      _.filter(stock?.inventory, {"type": 'PRODUCT'})?.map((item) => item?.items?.length > 0 ? (
                        <div key={"inventory-" + item.id} className='mb-3'>
                    {/* <p className=' text-uppercase'>{item.name}</p> */}
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead className="table-light text-uppercase">
                          <tr>
                            <th>Référence</th>
                            <th>Désignation</th>
                            <th>Stock initial</th>
                            <th>Entrées</th>
                            <th>Sorties</th>
                            <th>Stock</th>
                            {
                              !controlMode && <>
                                
                                <th>CMUP</th>
                                <th>Coût total</th>
                              </>
                            }
                            {
                              controlMode && <th>Stock Réel</th>
                            }
                            {
                              controlMode && <th>Ecart</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {item?.items?.map((stock) => {
                            return (
                              <tr key={'stock' + stock?.id}>
                                <th>{stock.product?.reference}</th>
                                <td>
                                  <span className="text-uppercase">{stock.product?.title}</span>
                                </td>
                                <td>{stock.stock_initial > 0 ? stock.stock_initial : ''}</td>
                                <td className='cursor-pointer' onClick={() => setShowStockMvt({"code": stock.product?.reference, "side": "IN"})} data-bs-toggle="modal" data-bs-target=".displ-stock-modal">{stock.stock_entry > 0 ? stock.stock_entry : ''}</td>
                                <td className='cursor-pointer' onClick={() => setShowStockMvt({"code": stock.product?.reference, "side": "OUT"})} data-bs-toggle="modal" data-bs-target=".displ-stock-modal">{stock.stock_out > 0 ? stock.stock_out : ''}</td>
                                  <td>{stock.quantity}</td>
                                {
                                  !controlMode && <>
                                    <td>{formatNumber(stock.product.buy_price)} </td>
                                    <td>{formatNumber(stock.quantity * stock.product.buy_price)} </td>
                                  </>
                                }
                                {
                                  controlMode && <td>
                                  {
                                    controlMode ? 
                                    <input type="number" value={stock.real_quantity} className="border-0 text-center" onChange={(e) => handleChangeStock(stock.id, e.target.value)} onFocus={handleFocus} style={{width: '50px'}} />
                                    : stock.real_quantity === 0 ? "" : stock.real_quantity
                                  }
                                </td>
                                }
                                {
                                  controlMode && <td>{stock.real_quantity ? (stock.real_quantity - stock.quantity) : (stock.quantity - stock.quantity) }</td>
                                }
                              </tr>
                            );
                          })}
                          {/* {stocks?.map((stock) => {
                            return (
                              <tr key={'stock-inv-' + stock?.id}>
                                <th>{stock.product?.reference}</th>
                                <td>{stock.product?.category}</td>
                                <td>{stock.product?.title}</td>
                                <td>{stock.quantity}</td>
                                <td>{stock.price} </td>
                                <td>{stock.location?.name}</td>
                              </tr>
                            );
                          })} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                      ) : (<div></div>))
                    }
                  </div>
                  
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inventory;
