import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const generateRepportPDF = async (employees) => {
  const pdf = new jsPDF();

  console.log(employees);
  
  for (let i = 0; i < employees.length; i++) {
    const employee = employees[i].employee;
    const element = document.getElementById(`report-${employee.id}`);

    if (element) {
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");

      if (i !== 0) pdf.addPage(); // Add a new page except for the first one

      pdf.addImage(imgData, "PNG", 10, 10, 190, 250);
    }
  }

  pdf.save("rapport_d_activite.pdf");
};


export { generateRepportPDF };