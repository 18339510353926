import useSWR from "swr";
import { URLS } from "../../../services/urls";
import { useSelector } from "react-redux";
import InvoiceTicket from "./InnvoiceTicket";
import { useEffect, useState } from "react";



export default function InvoiceNumber({invoiceNumber}) {

    const {active_agency} = useSelector((state) => state.agencies);

    const { data, isLoading } = useSWR(URLS.SEARCH.invoices(active_agency?.id, String(invoiceNumber).split("-")[1], 1, ``));

    const [selectedInvoice, setSelectedInvoice] = useState();

    const handleOpen = () => {
        // alert("Alors...")
        const ref = `#invoiceTicket-${String(invoiceNumber).replaceAll("-", "").toLowerCase()}`;

        console.log(ref);
        window.$(ref)?.modal('show');
    }

    useEffect(() => {
        if(data?.results?.length > 0){
            setSelectedInvoice(data?.results[0]);
        }
    }, [data]);


    return (
        <div>
            
        <div
          className="modal fade details-invoice-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeInvoiceModalLabel"
          aria-hidden="true"
          id={`invoiceTicket-${String(invoiceNumber).replaceAll("-", "").toLowerCase()}`}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeInvoiceModalLabel">
                  {'Facture ' + selectedInvoice?.reference}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              {!isLoading && <div className="modal-body">
                <InvoiceTicket invoice={selectedInvoice} />
              </div>}
            </div>
          </div>
        </div>
            <span className="text-underline" onClick={handleOpen}>{String(invoiceNumber)}</span>
        </div>
    ) 
}