import useSWR from 'swr';
import { URLS } from '../../../services/urls';
import { formatNumber, sumArray } from '../../../components/utils/functions';
import moment from 'moment';

const formatDate = (date) => {
  if (date) {
      // return String(date.toLocaleDateString()).split('/').reverse().join('-');
      return moment(date).format('YYYY-MM-DD');
  } else {
    return '9999-01-01';
  }
};

const DailySummaries = ({ hideGlobal, dates, agency }) => {
  const {
    data: employees,
    isLoading: loadingStats,
    mutate: refetchStats,
  } = useSWR(
    hideGlobal ?  URLS.STATS.EMPLOYEE.agencies(agency, formatDate(dates?.checkin), formatDate(dates?.checkout)) : URLS.STATS.EMPLOYEE.agencies(agency, formatDate(dates?.checkin), formatDate(dates?.checkout))
  );
  return (employees && <div className="py-2 row">
      <div className={!hideGlobal ? `col-lg-4 py-3 col-sm-12 mb-2` : 'col-lg-6 py-3 col-sm-12 mb-2'}>
        <div className="p-2 border border-1 d-flex">
          <div className='d-flex w-100'>
            <div className="my-auto w-100">
              <div className="bg-light p-3 mb-3">
                <p className="text-uppercase mb-0 text-center">Coiffeurs</p>
              </div>
              <table className="table mb-0">
                <tbody className="text-uppercase">
                  <tr className="text-uppercase">
                    <td className="bg-light">Total des prestations</td>
                    <td>
                      {sumArray(
                        employees?.map((i) => {
                          if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                            return i?.nbre_prestations;
                          } else {
                            return 0;
                          }
                        })
                      )}
                    </td>
                    <td className="bg-light">total des produits</td>
                    <td>
                      {formatNumber(
                        sumArray(
                            
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                                return i?.nbre_produits;
                            } else {
                                return 0;
                              }
                          })
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Total ca</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                                return i?.ca;
                            } else {
                                return 0;
                              }
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-danger">Primes EXCEPTIONNELLES</td>
                    <td className=" text-danger">
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                                return i?.prime_exeptionnelle;
                            } else {
                                return 0;
                              }
                          })
                        )
                      )}
                    </td>
                    
                  </tr>
                  {/* <tr className="text-uppercase">
                    <td className="bg-light">% de participation au CA</td>
                    <td>
                      {sumArray(
                        employees?.map((i) => {
                          return i?.ca_percentage;
                        })
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td className="bg-light">Salaire Fixe</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                                return parseFloat(i?.employee?.salary);
                            } else {
                                return 0;
                              }
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-danger">Avance sur salaire</td>
                    <td className=" text-danger">
                      {sumArray(
                        employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                                return i?.avance_salaire;
                            } else {
                                return 0;
                              }
                        })
                      )}
                    </td>
                    
                  </tr>
                  <tr>
                    <td className="bg-light">Commissions</td>
                      <td>
                        {formatNumber(
                          sumArray(
                            employees?.map((i) => {
                              if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                                  return i?.commission;
                              } else {
                                  return 0;
                                }
                            })
                          )
                        )}
                      </td>
                    <td className="bg-light text-danger">Retenue sur salaire</td>
                    <td className=" text-danger">
                      {sumArray(
                        employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                                return i?.retenue_salaire;
                            } else {
                                return 0;
                              }
                        })
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">% de participation au CA</td>
                      <td>
                        {formatNumber(sumArray(
                          employees?.map((i) => {
                              if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                                  return parseFloat(i?.ca_percentage);
                              } else {
                                  return 0;
                                }
                          })
                        ))}
                      </td>
                    <td className="bg-light text-success">Salaire Net</td>
                    <td className=' text-success'>
                      {' '}
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                                return (
                                  parseFloat(i?.employee?.salary) +
                                  i?.commission -
                                  i?.avance_salaire -
                                  i?.retenue_salaire
                                );
                            } else {
                                return 0;
                              }
                          })
                        )
                      )}{' '}
                    </td>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {!hideGlobal && <div className="col-lg-4 col-sm-12">
        <div className="p-2 border border-2 d-flex border-primary h-100">
          <div className='m-auto w-100'>
            <div className="mb-2">
              <div className="bg-light p-3 mb-3">
                <p className="text-uppercase mb-0 text-center">Global</p>
              </div>
              <table className="table mb-0">
                <tbody className="text-uppercase ">
                  <tr className="text-uppercase">
                    <td className="bg-light">Total des prestations</td>
                    <td>
                      {sumArray(
                        employees?.map((i) => {
                          // if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                          // }
                          return i?.nbre_prestations;
                        })
                      )}
                    </td>
                    <td className="bg-light">total des produits</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return i?.nbre_produits;
                          })
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Total ca</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return i?.ca;
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-">Primes EXCEPTIONNELLES</td>
                    <td className=" text-">
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return i?.prime_exeptionnelle;
                          })
                        )
                      )}
                    </td>
                    
                    
                  </tr>
                  {/* <tr className="text-uppercase">
                    <td className="bg-light">% de participation au CA</td>
                    <td>
                      {sumArray(
                        employees?.map((i) => {
                          return i?.ca_percentage;
                        })
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td className="bg-light">Commissions</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return i?.commission;
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-danger">Avance sur salaire</td>
                    <td className=" text-danger">
                      {sumArray(
                        employees?.map((i) => {
                          return i?.avance_salaire;
                        })
                      )}
                    </td>
                  </tr>
                  <tr>
                  <td className="bg-light">Salaire Fixe</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return parseFloat(i?.employee?.salary);
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-danger">Retenue sur salaire</td>
                    <td className=" text-danger">
                      {sumArray(
                        employees?.map((i) => {
                          return i?.retenue_salaire;
                        })
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light text-success">Salaire Net</td>
                    <td className=' text-success'>
                      {' '}
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return (
                              parseFloat(i?.employee?.salary) +
                              i?.commission -
                              i?.avance_salaire -
                              i?.retenue_salaire
                            );
                          })
                        )
                      )}{' '}
                    </td>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>}
      <div className={!hideGlobal ? `col-lg-4 py-3 col-sm-12` : 'col-lg-6 py-3 col-sm-12'}>
        <div className="p-2 border border-1">
          <div className='d-flex'>
            <div className="my-auto w-100">
              <div className="bg-light p-3 mb-3">
                <p className="text-uppercase mb-0 text-center">Estheticiennes</p>
              </div>
              <table className="table mb-0">
                <tbody className="text-uppercase">
                  <tr className="text-uppercase">
                    <td className="bg-light">Total des prestations</td>
                    <td>
                      {sumArray(
                        employees?.map((i) => {
                          if(String(i?.employee?.role).toUpperCase() !== "COIFFEUR"){
                            return i?.nbre_prestations;
                          } else {
                            return 0;
                          }
                        })
                      )}
                    </td>
                    <td className="bg-light">total des produits</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() !== "COIFFEUR"){
                                return i?.nbre_produits;
                              } else {
                                return 0;
                              }
                              
                          })
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Total ca</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() !== "COIFFEUR"){
                                return i?.ca;
                            } else {
                              return 0;
                            }
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light">% de participation au CA</td>
                    <td>
                      {formatNumber(sumArray(
                        employees?.map((i) => {
                          if(String(i?.employee?.role).toUpperCase() !== "COIFFEUR"){
                              return parseFloat(i?.ca_percentage);
                          } else {
                            return 0;
                          }
                        })
                      ))}
                    </td>
                  </tr>
                  {/* <tr className="text-uppercase">
                    <td className="bg-light">% de participation au CA</td>
                    <td>
                      {sumArray(
                        employees?.map((i) => {
                          return i?.ca_percentage;
                        })
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td className="bg-light">Salaire Fixe</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() !== "COIFFEUR"){
                                return parseFloat(i?.employee?.salary);
                            } else {
                              return 0;
                            }
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-danger">Avance sur salaire</td>
                    <td className=" text-danger">
                      {sumArray(
                        employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() != "COIFFEUR"){
                                return i?.avance_salaire;
                            } else {
                              return 0;
                            }
                        })
                      )}
                    </td>
                  </tr>
                  <tr>
                  <td className="bg-light">Commissions</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() != "COIFFEUR"){
                                return i?.commission;
                            } else {
                              return 0;
                            }
                          })
                        )
                      )}
                    </td>
                    
                    <td className="bg-light text-danger">Retenue sur salaire</td>
                    <td className=" text-danger">
                      {sumArray(
                        employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() != "COIFFEUR"){
                                return i?.retenue_salaire;
                            } else {
                              return 0;
                            }
                        })
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light text-">Primes EXCEPTIONNELLES</td>
                    <td className=" text-">
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() != "COIFFEUR"){
                                return i?.prime_exeptionnelle;
                            } else {
                              return 0;
                            }
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-success">Salaire Net</td>
                    <td className=' text-success'>
                      {' '}
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            if(String(i?.employee?.role).toUpperCase() != "COIFFEUR"){
                                return (
                                  parseFloat(i?.employee?.salary) +
                                  i?.commission -
                                  i?.avance_salaire -
                                  i?.retenue_salaire
                                );
                            } else {
                              return 0;
                            }
                          })
                        )
                      )}{' '}
                    </td>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {
        hideGlobal && <div className="row">
          <div className="col-3"></div>
          <div className="col-lg-6 col-sm-12">
        <div className="p-2 border border-2 border-primary h-100">
          <div className='d-flex'>
            <div className="m-auto w-100">
              <div className="bg-light p-3 mb-3">
                <p className="text-uppercase mb-0 text-center">Global</p>
              </div>
              <table className="table">
                <tbody className="text-uppercase">
                  <tr className="text-uppercase">
                    <td className="bg-light">Total des prestations</td>
                    <td>
                      {sumArray(
                        employees?.map((i) => {
                          // if(String(i?.employee?.role).toUpperCase() === "COIFFEUR"){
                          // }
                          return i?.nbre_prestations;
                        })
                      )}
                    </td>
                    <td className="bg-light">total des produits</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return i?.nbre_produits;
                          })
                        )
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light">Total ca</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return i?.ca;
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-">Primes EXCEPTIONNELLES</td>
                    <td className=" text-">
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return i?.prime_exeptionnelle;
                          })
                        )
                      )}
                    </td>
                    
                    
                  </tr>
                  {/* <tr className="text-uppercase">
                    <td className="bg-light">% de participation au CA</td>
                    <td>
                      {sumArray(
                        employees?.map((i) => {
                          return i?.ca_percentage;
                        })
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td className="bg-light">Commissions</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return i?.commission;
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-danger">Avance sur salaire</td>
                    <td className=" text-danger">
                      {sumArray(
                        employees?.map((i) => {
                          return i?.avance_salaire;
                        })
                      )}
                    </td>
                  </tr>
                  <tr>
                  <td className="bg-light">Salaire Fixe</td>
                    <td>
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return parseFloat(i?.employee?.salary);
                          })
                        )
                      )}
                    </td>
                    <td className="bg-light text-danger">Retenue sur salaire</td>
                    <td className=" text-danger">
                      {sumArray(
                        employees?.map((i) => {
                          return i?.retenue_salaire;
                        })
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bg-light text-success">Salaire Net</td>
                    <td className=' text-success'>
                      {' '}
                      {formatNumber(
                        sumArray(
                          employees?.map((i) => {
                            return (
                              parseFloat(i?.employee?.salary) +
                              i?.commission -
                              i?.avance_salaire -
                              i?.retenue_salaire
                            );
                          })
                        )
                      )}{' '}
                    </td>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
        </div>
      }
    </div>)
  
};

export default DailySummaries;
