import moment from 'moment';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import { date } from 'yup';
import { useSelector } from 'react-redux';
import { formatNumber, sumArray } from '../../components/utils/functions';
import DailySummary from './components/DailySummary';
import { DatePicker } from 'react-responsive-calendar-picker';
import DailySummaries from './components/DailyPerfSummaries';
import { useReactToPrint } from 'react-to-print';
import LoaderPage from '../../components/LoaderPage';
import PageTitle from '../../components/PageTitle';

const formatDate = (date) => {
  if (date) {
    // return String(date.toLocaleDateString()).split('/').reverse().join('-');
    return moment(date).format('YYYY-MM-DD');
  } else {
    return '9999-01-01';
  }
};

const PerfGlobalReport = () => {
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { active_agency } = useSelector((state) => state.agencies);
  const reportRef = useRef();

  const {
    data: report,
    isLoading: loadingCa,
    mutate: mutateCa,
  } = useSWR(
    URLS.STATS.REPPORT.perf(
      formatDate(dates.checkin),
      formatDate(dates.checkout),
      active_agency?.id
    )
  );

  const handlePrint = useReactToPrint({
    content: () => reportRef?.current,
    documentTitle: "Rapport de performance " + moment().format("DD/MM/YYYY"),
    // onBeforeGetContent: () => setHideButton(true),
    // onAfterPrint: () => setHideButton(true)
  });

  return (
    <div className='perf-'>
      <div className="row">
        <div className="col-md-6">
          <PageTitle
            title={'Rapport de performance Global'}
            breadcrumbs={
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Rapport de performance Global hereee
                </li>
              </ol>
            }
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="py-3">
            <h3 className="text-uppercas">Rapport de performance Global</h3>
          </div>
            <div className="col-12">
              <div className="d-flex">
                <div className="">
                <div>
                <div className="py-3 position-relative" style={{ zIndex: '1' }}>
                  <DatePicker
                    dates={dates}
                    setDates={setDates}
                    open={open}
                    setOpen={setOpen}
                    normal={true}
                  >
                    <button className="btn btn-primary rounded-0" onClick={() => setOpen(!open)}>
                      Rapport du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                      {dates.checkout && 'au'}{' '}
                      {dates.checkout && dates.checkout.toLocaleDateString()}
                    </button>
                  </DatePicker>
                </div>
              </div>
                </div>
                <div className="ms-auto">
                {<button onClick={handlePrint} type="button" className="px-3 rounded-0 btn btn-primary waves-effect waves-light">
                    {' '}
                    Imprimer
                  </button>}
                </div>
              </div>
            </div>
          <div className="row" ref={reportRef}>
            <div className="col-12 mb-5">
                {<DailySummaries hideGlobal={true}  dates={dates} agency={active_agency?.id} />}
                
            </div>
            <div className="mt-5"></div>
            {
            loadingCa && <LoaderPage />
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerfGlobalReport;
