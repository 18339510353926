import moment from 'moment';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { formatNumber, sumArray } from '../../components/utils/functions';
import { DatePicker } from 'react-responsive-calendar-picker';
import { useReactToPrint } from 'react-to-print';
import LoaderPage from '../../components/LoaderPage';
import PdfHeader from '../../components/Pdf/PdfHeader';
import PageTitle from '../../components/PageTitle';

const formatDate = (date) => {
  if (date) {
      // return String(date.toLocaleDateString()).split('/').reverse().join('-');
      return moment(date).format('YYYY-MM-DD');
  } else {
    return '9999-01-01';
  }
};

const GlobalCalendarReport = () => {
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { active_agency, agencies } = useSelector((state) => state.agencies);

  const reportRef = useRef();

  const {
    data: report,
    isLoading: loadingCa, 
    mutate: mutateCa,
  } = useSWR(
    URLS.STATS.REPPORT.global(
      formatDate(dates.checkin),
      formatDate(dates.checkout),
      active_agency?.id !== "" ? active_agency?.id : agencies[0]?.id
    )
  );
  const handlePrint = useReactToPrint({
    content: () => reportRef?.current,
    documentTitle: `Rapport du ${dates.checkin && dates.checkin.toLocaleDateString()} {' '}
    ${dates.checkout && 'au'}{' '}
    ${dates.checkout && dates.checkout.toLocaleDateString()}`,
    // onBeforeGetContent: () => setHideButton(true),
    // onAfterPrint: () => setHideButton(true)
  });


  const getTodayObj = (objs, date) => {
    console.log(objs)
    if(objs){
      const item = objs?.filter((obj) => {
        if(obj.date === date){
          console.log("Valid date");
          return obj;
        }
      })
      return item[0]
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <PageTitle
            title={'Calendrier'}
            breadcrumbs={
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/repports/global">Rapport d'activité Global </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Calendrier
                </li>
              </ol>
            }
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="py-">
            <h3 className="text-uppercas">Rapport d'activité global (Calendrier)</h3>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
              <div>
                <div className="py-3 position-relative" style={{ zIndex: '1' }}>
                  <DatePicker
                    dates={dates}
                    setDates={setDates}
                    open={open}
                    setOpen={setOpen}
                    normal={true}
                  >
                    <button className="btn btn-primary rounded-0" onClick={() => setOpen(!open)}>
                      Rapport du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                      {dates.checkout && 'au'}{' '}
                      {dates.checkout && dates.checkout.toLocaleDateString()}
                    </button>
                  </DatePicker>
                </div>
              </div>
                <div className="ms-auto my-auto">
                <div className="d-flex">
                    <Link
                      to={'/repports/global/calendar'}
                      className="btn me-2 btn-primary rounded-0 text-uppercase"
                    >
                      Voir le recap
                    </Link>
                    <button
                      className="btn btn-primary rounded-0 text-uppercase"
                      onClick={handlePrint}
                    >
                      Imprimer
                    </button>
                  </div>
                </div>
              </div>
              
            </div>

            {
                loadingCa && <div className="py-3">
                    <LoaderPage/>
                </div>
            }
            {!loadingCa && (
              <div className="col-md-12 rapport-activity" ref={reportRef}>
                <div className="border border-1 p-3">
                  <div className="my-3 text-center">
                    <PdfHeader />
                  </div>
                  <div className="mb-2">
                    <table className="table">
                      <tbody>
                        <tr className="text-uppercase">
                          <td className="bg-light">Agence</td>
                          <td>{report?.agency?.name}</td>
                          <td className="bg-light">Caisse principale</td>
                          <td>{formatNumber(report?.agency?.wallet)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="bg-light p-3 mb-3">
                    <p className="text-uppercase mb-0 text-center">RAPPORT D'ACTIVITE PROGRESSIF</p>
                  </div>
                  {/* <div className="table-responsive"> */}
                  <div className="table-responsive">
                    <table className="table sticky-first-column ">
                      <thead style={{ position: 'sticky', top: 0 }}>
                        <tr className="text-uppercase bg-light">
                          <th className='text-black'>LIBELLES</th>
                          {
                            report?.stats?.map((rep, index) => (
                                <th key={'th-' + index}>
                                    {moment(rep.date).format("DD MM")}
                                </th>
                            ))
                          }
                        </tr>
                      </thead>
                      <tbody className='text-uppercase'>
                        <tr className="badge-soft-success fw-bold">
                            {/* <td className="text-success">+</td> */}
                            <td className='text-black bg-success text-start'>Chiffre d'affaires sur les Prestations</td>
                            {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-date' + idx2}>
                                        {formatNumber(
                                            sumArray(
                                            getTodayObj(report?.stats, stat.date)?.report?.map((i) => i.amount_services)
                                            )
                                        )}
                                    </td>
                                ))
                            }
                        </tr>
                        {/* Details des prestations */}
                            {report?.stats[0]?.report?.map((i, index) =>
                              i?.category?.type === 'SERVICE' ? (
                                <tr className=" small" key={'prsta-' + index}>
                                  {/* <td className="text-success"></td> */}
                                  <td className='text-black text-start'>
                                    - {i?.category?.name}
                                  </td>
                                    {
                                        report?.stats?.map((stat, idx2) => (
                                            <td key={'row-date-p-' + idx2}>
                                                {formatNumber(
                                                    sumArray(
                                                        getTodayObj(report?.stats, stat.date)?.report?.map((j) => i?.category?.id === j?.category?.id ? j.amount_services : 0)
                                                    )
                                                )}
                                            </td>
                                        ))
                                    }
                                  {/* <td>{formatNumber(i?.amount_services)}</td> */}
                                </tr>
                              ) : null
                            )}
                            <tr className="badge-soft-success fw-bold">
                              {/* <td className="text-success">+</td> */}
                              <td className='text-black bg-success text-start'>Chiffre d'affaires sur les Produits</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-date' + idx2}>
                                        <strong>
                                        {formatNumber(
                                            sumArray(
                                            getTodayObj(report?.stats, stat.date)?.report?.map((i) => i.amount_products)
                                            )
                                        )}
                                        </strong>
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className="fw-bold bg-primary-2">
                              {/* <td className="text-danger"></td> */}
                              <td className='text-black text-start'>Chiffre d'affaires Total 1</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-date' + idx2}>
                                        
                                        {formatNumber(
                                          sumArray(
                                            getTodayObj(report?.stats, stat.date)?.report?.map((i) => i.amount_products)
                                          ) +
                                            sumArray(
                                              getTodayObj(report?.stats, stat.date)?.report?.map((i) => i.amount_services)
                                            )
                                        )}
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className="badge-soft-success fw-bold">
                              {/* <td className="text-success">+</td> */}
                              <td className='text-black bg-success text-start'>Total des soldes en compte</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-appro' + idx2}>
                                        {formatNumber(
                                          getTodayObj(report?.stats, stat?.date)?.soldes_clients
                                        )}
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className="fw-bold bg-primary-2">
                              {/* <td className="text-danger"></td> */}
                              <td className='text-black text-start'>Chiffre d'affaires Total 2</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-date' + idx2}>
                                        
                                        {formatNumber(
                                          sumArray(
                                            getTodayObj(report?.stats, stat.date)?.report?.map((i) => i.amount_products)
                                          ) +
                                            sumArray(
                                              getTodayObj(report?.stats, stat.date)?.report?.map((i) => i.amount_services)
                                            ) +
                                            sumArray(
                                              _.filter(getTodayObj(report?.stats, stat.date)?.transactions ?? [], {
                                                reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                              })?.map((i) => i.amount)
                                            )
                                        )}
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className="badge-soft-danger fw-bold">
                              {/* <td className="text-danger">-</td> */}
                              <td className='text-black bg-danger text-start'>Charges Globales</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-date-global' + idx2}>
                                        
                                    </td>
                                ))
                            }
                            </tr>
                            <tr className=" small">
                              {/* <td className="text-danger"></td> */}
                              <td className='text-black  text-start'>
                                - Caisse principale
                              </td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-caisse-pricipale' + idx2}>

                                        {formatNumber(
                                          sumArray(
                                            _.filter(getTodayObj(report?.stats, stat.date)?.caisses ?? [], {
                                              is_admin: false,
                                              is_main: true,
                                            })[0]?.transactions.map((i) => i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0)
                                          )
                                        )}
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className=" small">
                              {/* <td className="text-danger"></td> */}
                              <td className='text-black text-start'>
                                - Coffre
                              </td>
                                {
                                    report?.stats?.map((stat, idx2) => (
                                        <td key={'row-caisse-coffre' + idx2}>
                                            
                                            {formatNumber(
                                                sumArray(
                                                  _.filter(getTodayObj(report?.stats, stat?.date)?.caisses ?? [], {
                                                    is_admin: true,
                                                    is_main: true,
                                                  })[0]?.transactions.map((i) => i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0)
                                                )
                                              )}
                                        </td>
                                    ))
                                }
                            </tr>
                            <tr className="fw-bold bg-primary-2">
                              {/* <td className="text-danger"></td> */}
                              <td className='text-black text-start'>Marge Commerciale 1</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-marge-commercial' + idx2}>
                                        
                                        {formatNumber(
                                            sumArray(
                                              getTodayObj(report?.stats, stat.date)?.report?.map((i) => i.amount_products)
                                            ) +
                                              sumArray(
                                                getTodayObj(report?.stats, stat?.date)?.report?.map((i) => i.amount_services)
                                              ) +
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.transactions ?? [], {
                                                  reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                                })?.map((i) => i.amount)
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.caisses ?? [], {
                                                  is_admin: true,
                                                  is_main: true,
                                                })[0]?.transactions.map((i) => i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0)
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.caisses ?? [], {
                                                  is_admin: false,
                                                  is_main: true,
                                                })[0]?.transactions.map((i) => i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0)
                                              )
                                          )}
                                    </td>
                                ))
                              }
                            </tr>
                            {/* <tr className="badge-soft-danger fw-bold">
                              <td className='text-black bg-danger text-start'>élements exceptionnels</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-element-exceptionnel' + idx2}></td>
                                ))
                              }
                            </tr> 
                            <tr className=" small">
                              <td className='text-black text-start'>
                                - Paiements en compte
                              </td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-caisse-pricipale' + idx2}>
                                        
                                        {formatNumber(
                                          sumArray(
                                            _.filter(getTodayObj(report?.stats, stat?.date)?.payment_methods ?? [], {
                                              payment_method: "SOLDE_DISPONIBLE",
                                            })[0]?.data.map((i) => parseFloat(i?.amount))
                                          )
                                        )}
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className=" small">
                              <td className='text-black text-start'>
                                - Crédit client
                              </td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-caisse-pricipale' + idx2}>
                                        
                                        {
                                          formatNumber(
                                            sumArray(
                                              _.filter(getTodayObj(report?.stats, stat?.date)?.payment_methods ?? [], {
                                                payment_method: "CREDIT",
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        }
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className="small">
                              <td className='text-black text-start'>
                                - Offres/Gratuités
                              </td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-caisse-pricipale' + idx2}>
                                        
                                        {
                                          formatNumber(
                                            sumArray(
                                              _.filter(getTodayObj(report?.stats, stat?.date)?.payment_methods ?? [], {
                                                payment_method: "OFFER",
                                              })[0]?.data.map((i) => parseFloat(i?.amount))
                                            )
                                          )
                                        }
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className="fw-bold bg-primary-2">
                              <td className='text-black text-start'>Marge Commerciale 2</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-caisse-pricipale' + idx2}>
                                        
                                        {formatNumber(
                                            sumArray(
                                              getTodayObj(report?.stats, stat?.date)?.report?.map((i) => i.amount_products)
                                            ) +
                                              sumArray(
                                                getTodayObj(report?.stats, stat?.date)?.report?.map((i) => i.amount_services)
                                              ) +
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.transactions ?? [], {
                                                  reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                                })?.map((i) => i.amount)
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.caisses ?? [], {
                                                  is_admin: true,
                                                  is_main: true,
                                                })[0]?.transactions.map((i) => i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0)
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.caisses ?? [], {
                                                  is_admin: false,
                                                  is_main: true,
                                                })[0]?.transactions.map((i) => i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0)
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.payment_methods ?? [], {
                                                  payment_method: "SOLDE_DISPONIBLE",
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.payment_methods ?? [], {
                                                  payment_method: "CREDIT",
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              ) - 
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.payment_methods ?? [], {
                                                  payment_method: "OFFER",
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              )
                                          )}
                                    </td>
                                ))
                              }
                            </tr>*/}
                            <tr className="badge-soft-danger fw-bold">
                              {/* <td className="text-danger">-</td> */}
                              <td className='text-black bg-danger text-start'>Charges du personnel</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-charge-personnel' + idx2}>
                                        
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className="small">
                              {/* <td className="text-danger"></td> */}
                              <td className='text-black test-start'>
                                - Salaires net
                              </td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-salaire-net' + idx2}>
                                        {
                                          formatNumber(
                                            sumArray(
                                              _.filter(getTodayObj(report?.stats, stat?.date)?.transactions ?? [], {
                                                reason: 'SALAIRES',
                                              })?.map((i) => i.amount)
                                            )
                                          )
                                        }
                                    </td>
                                ))
                              }
                            </tr>
                            <tr className="bg-primary-2 fw-bold">
                              {/* <td className="text-success"></td> */}
                              <td className='text-black'>Résultat</td>
                              {
                                report?.stats?.map((stat, idx2) => (
                                    <td key={'row-resultat' + idx2}>
                                        
                                        {formatNumber(
                                            sumArray(
                                              getTodayObj(report?.stats, stat?.date)?.report?.map((i) => i.amount_products)
                                            ) +
                                              sumArray(
                                                getTodayObj(report?.stats, stat?.date)?.report?.map((i) => i.amount_services)
                                              ) +
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.transactions ?? [], {
                                                  reason: 'APPROVISIONNEMENT_DE_COMPTE',
                                                })?.map((i) => i.amount)
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.caisses ?? [], {
                                                  is_admin: true,
                                                  is_main: true,
                                                })[0]?.transactions.map((i) => i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0)
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.caisses ?? [], {
                                                  is_admin: false,
                                                  is_main: true,
                                                })[0]?.transactions.map((i) => i?.type === "WITHDRAWAL" ? parseFloat(i?.amount) : 0)
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.payment_methods ?? [], {
                                                  payment_method: "SOLDE_DISPONIBLE",
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.payment_methods ?? [], {
                                                  payment_method: "CREDIT",
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              ) - 
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.payment_methods ?? [], {
                                                  payment_method: "OFFER",
                                                })[0]?.data.map((i) => parseFloat(i?.amount))
                                              ) -
                                              sumArray(
                                                _.filter(getTodayObj(report?.stats, stat?.date)?.transactions ?? [], {
                                                  reason: 'SALAIRES',
                                                })?.map((i) => i.amount)
                                              )
                                          )}
                                    </td>
                                ))
                              }
                            </tr>
                        {/* {report?.stats?.map((stat, idx2) => (
                          <tr key={'perf-day-' + idx2}>
                            <td className="bg-info">{moment(stat?.date).format('DD/MM/YYYY')}</td>
                            <td>{sumArray(stat?.report?.map((r) => r.quantity_products))}</td>
                            <td>
                              {sumArray(stat?.report?.map((r) => r.amount_products)) > 0 ? (
                                <span className="badge badge-soft-success">
                                  {formatNumber(
                                    sumArray(stat?.report?.map((r) => r.amount_products))
                                  )}
                                </span>
                              ) : (
                                sumArray(stat?.report?.map((r) => r.amount_products))
                              )}
                            </td>
                            {stat?.report?.map((re, idx3) => (
                              <td
                                key={'th-serv-3-' + idx3}
                                style={{ backgroundColor: '#16eb165c' }}
                              >
                                {re?.amount_services > 0 ? (
                                  <span className="badge badge-soft-success">
                                    {re?.amount_services}
                                  </span>
                                ) : (
                                  re?.amount_services
                                )}
                              </td>
                            ))}
                            <td style={{ backgroundColor: '#ffd967' }}>
                              {sumArray(stat?.report?.map((r) => r.price)) > 0 ? (
                                <span className="badge badge-soft-success">
                                  {formatNumber(sumArray(stat?.report?.map((r) => r.price)))}
                                </span>
                              ) : (
                                formatNumber(sumArray(stat?.report?.map((r) => r.price)))
                              )}
                            </td>
                            {/* Payment methods *
                            {stat.payment_methods?.map((re, idx3) =>
                              re?.payment_method != 'NOT_DEFINED' ? (
                                <td
                                  className="whitespace-nowrap text-white"
                                  key={'th-serv-' + idx3}
                                  style={{ backgroundColor: '#9cc1e6' }}
                                >
                                  {sumArray(re?.data?.map((r) => r.amount)) > 0 ? (
                                    <span className="badge badge-soft-successd text-white">
                                      {formatNumber(sumArray(re?.data?.map((r) => r.amount)))}
                                    </span>
                                  ) : (
                                    formatNumber(sumArray(re?.data?.map((r) => r.amount)))
                                  )}
                                </td>
                              ) : null
                            )}
                            {/* Caisses *
                            {stat.caisses?.map((re, idx3) =>
                              re?.mobile != 'NOT_DEFINED' ? (
                                <td
                                  className="whitespace-nowrap text-white"
                                  key={'th-serv-' + idx3}
                                  style={{ backgroundColor: 'rgb(168 208 141)' }}
                                >
                                  {re.sold > 0 ? (
                                    <span className="badge badge-soft-succes">
                                      {formatNumber(re?.sold)}
                                    </span>
                                  ) : (
                                    formatNumber(re?.sold)
                                  )}
                                </td>
                              ) : null
                            )}
                          </tr>
                        ))} */}
                      </tbody>
                    </table>
                  </div>
                  {
                    // <DailySummary dates={dates} employee={report.agenncy} />
                  }
                  {/* <div className="bg-light p-3 mb-3">
                    <p className="text-uppercase mb-0 text-center">Autres</p>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalCalendarReport;
