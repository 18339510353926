import _ from "lodash";
import {URLS} from "../../services/urls"
import moment from "moment";
import { API } from "../../services/axios";
// import { printInvoice } from "./posActions";
import { toast } from "react-toastify";

let ls = {}

ls.set = window.localStorage.setItem.bind(localStorage);
ls.get = window.localStorage.getItem.bind(localStorage);

const getUserDetails = () => JSON.parse( window.localStorage.getItem("user-details"));
const setUserDetails = (details) => window.localStorage.setItem("user-details", JSON.stringify(details));

const appScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
}

const formatLinkString = (link, defaultLink) => {
  if(link?.includes("https://") || link?.includes("http://")){
    return link;
  } else if(link) {
    return URLS.BASE_API + link;
  } else {
    return defaultLink ?? "/assets/images/no-image.jpeg"
  }
}

const validatePhoneNumber = (
  inputNumber,
  country,
  isDirty,
  phoneLength
) => {
  if (isDirty) {
    if (
      inputNumber &&
      inputNumber?.replace(country.dialCode, '')?.trim() === ''
    ) {
      return false;
    } else if (inputNumber.length < phoneLength) {
      return false;
    }
    return true;
  }
  return false;
};

const getQuantity = (category, searchResults, defaultValue, canEvaluate) => {

  if(canEvaluate){

    return searchResults.filter((item) => item.industry.id === category.id).length

  } else {

    return defaultValue;
  }

}

const sumArray = (arr) => {
  let list = arr ?? []
  const sum = list.reduce((partialSum, a) => partialSum + a, 0);
  return sum;

}

const getPaymentMethodIcon = (paymenntMethod) => {

  switch (paymenntMethod) {
    case "OM":
      return <span> <i className="mdi mdi-18px mdi-cellphone-wireless me-1"></i> {String(paymenntMethod).replaceAll("_", " ")} </span>
    case "MOMO":
      return <span> <i className="mdi mdi-18px mdi-cellphone-wireless me-1"></i> {String(paymenntMethod).replaceAll("_", " ")} </span>
    case "PG":
      return <span> <i className="fab fa-cc-visa me-1"></i> {String(paymenntMethod).replaceAll("_", " ")} </span>
    case "VISA_CARD":
      return <span> <i className="fab fa-cc-visa me-1"></i> {String(paymenntMethod).replaceAll("_", " ")} </span>
    case "SOLDE_DISPONIBLE":
      return <span> <i className="mdi mdi-wallet mdi-18px me-1"></i> {String(paymenntMethod).replaceAll("_", " ")} </span>
    case "CASH":
      return <span> <i className="fab fa-cc-money-bills me-1"></i> {String(paymenntMethod).replaceAll("_", " ")} </span>
  
    default:
      return <span> <i className="mdi mdi-18px mdi-contactless-payment me-1"></i> {String(paymenntMethod).replaceAll("_", " ")} </span>
  }
}

const getProductOrServiceType = (list, id) => {
  const selected = _.filter(list ?? [], {id: id});

  if (selected.length > 0){
    return selected[0]?.type
  } else {
    return ""
  }
}

const getCustomerSold = (list, id) => {
  const selected = _.filter(list ?? [], {id: id});

  if (selected.length > 0){
    return selected[0]?.type
  } else {
    return ""
  }
}

const handleFocus = (event) => event.target.select();


const handlePrintInvoice = (orderId) => {
  // The goal here is to fetch the invoice tha belong to this order and print it.

  try {
    API.get(URLS.INVOICES.byOrder(orderId)).then((resp) => {
      // printInvoice(resp.data);
    })
  } catch (error) {
    toast.error("Erreur d'impression, veuiller reessayer!")
  }

}

const getViewedTransaction = () => {
  const viewed = JSON.parse(window.localStorage.getItem("viewedTransactions"));
  if(viewed){
    return viewed;
  } else {
    return [];
  }
}

const setViewedTransaction = (item) => {
  let actualItems = JSON.parse(window.localStorage.getItem("viewedTransactions"));
  if(actualItems == undefined){
    actualItems = []
  }
  window.localStorage.setItem("viewedTransactions", JSON.stringify([item, ...actualItems]));
  
}

const formatNumber = (nbr) => {

  return Math.round(Number(nbr)).toLocaleString()
}

const adminGroups = ["RESPONSABLE_EXPLOITATION", "CHEF_AGENCE", "CAISSIER" ]

const filterEmployee = (list, group) => {
  switch (group) {
    case "admin":
      return list?.filter((item) => adminGroups.includes(item.role));
    case "esthe":
      return list?.filter((item) => item.role === "ESTHETICIENNE");
    case "coiff":
      return list?.filter((item) => item.role === "COIFFEUR");
    case "autres":
      return list?.filter((item) => item.role === "AUTRES");
  
    default:
      return [];
  }

}

const formatDate = (date) => {
  if (date) {
    // return String(date.toLocaleDateString()).split('/').reverse().join('-');
    return moment(date).format('yyyy-MM-DD');
  } else {
    return '9999-01-01';
  }
};

// Compare two dates
/*
The method returns 1 if dateTimeA is greater than dateTimeB

The method returns 0 if dateTimeA equals dateTimeB

The method returns -1 if dateTimeA is less than dateTimeB
*/
function compareDates(dateTimeA, dateTimeB) {
  var momentA = moment(dateTimeA,"DD/MM/YYYY").valueOf();
  var momentB = moment(dateTimeB,"DD/MM/YYYY").valueOf();
  if (momentA > momentB) return 1;
  else if (momentA < momentB) return -1;
  else return 0;
}

const getFirstItem = (items) => {
  console.log("items", items)
  if (items) {
    return items[0]
  } else {
    return undefined
  }
}

const handleToggleSidbar = () => {
  window?.$("body")?.toggleClass('sidebar-enable')
  window?.$("body")?.toggleClass('vertical-collpsed')
}

const formatPercentage = (value) => {

  if(value > 100) {
    return '+100'
  } else if (!Number.isInteger(value)){
    return `${Math.floor(value) > 0 ? '+' : ''}${Math.floor(value)}`;
  }
   else {
    return value ?? 0;
  }
}

const formateDate = (date) => {
  
  return (
    <span>
      <span>{moment(date).format('DD/MM/YYYY')}</span>
      <span className="ms-2">{moment(date).format('HH:mm')}</span>
    </span>
  )
}

// Combine arrays of objects and aggregate data based on a unique property


const aggregateAgenciesWallets = (wallets) => {
  
  // Group by 'mobile'
  const grouped = _.groupBy(wallets, 'mobile');

  // Combine and aggregate data
  const combined = _.map(grouped, (items, key) => {
      return _.reduce(items, (result, item) => {
          result.sold += item.sold;
          result.is_main = result.is_main || item.is_main;
          result.is_admin = result.is_admin || item.is_admin;
          result.transactions = _.concat(result.transactions, item.transactions);
          return result;
      }, {
          mobile: key,
          sold: 0,
          is_main: false,
          is_admin: false,
          transactions: []
      });
  });

  return combined;
}

const groupTransactions = (transactions, transactions2) => {
  const copy = transactions;
  console.log( "original", transactions2, );
  const groupedTransactions = transactions.reduce((acc, transaction) => {
    // Si la désignation existe déjà dans l'accumulateur, on additionne les montants

      if (acc[transaction.designation]) {
        acc[transaction.designation].totalAmount = parseFloat(acc[transaction.designation].totalAmount) + parseFloat(transaction.amount);
      } else {
        // Sinon, on l'ajoute avec l'amount courant
        if(String(transaction.designation).includes("FV-")){

          acc[transaction.designation] = {...transaction, "totalAmount": transaction.amount};
        } else {
          acc[transaction.reference] = {...transaction, "totalAmount": transaction.amount};
        }
      }
    return acc;
  }, {});

  
  let transactionsWithoutFV = copy.filter(item => !String(item.designation).includes("FV-"));
  console.log("groupedTransactions", groupedTransactions, "\n", "original", transactions2, );

  return Object.values(groupedTransactions);

}


export {groupTransactions, aggregateAgenciesWallets, formateDate, formatPercentage, handleToggleSidbar, compareDates, getFirstItem, filterEmployee, formatDate, setViewedTransaction, formatNumber, getViewedTransaction, ls, handlePrintInvoice, sumArray, setUserDetails, getUserDetails, appScrollTo, formatLinkString, validatePhoneNumber, getQuantity, getPaymentMethodIcon, getProductOrServiceType, handleFocus}